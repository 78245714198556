import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/styles';
import { Grid, Box, MenuItem, TextField, Button, Tooltip } from '@material-ui/core';
import { colors } from 'utils/colorsSteps';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { ModalSchedule } from '../../components';
import { generateUrl } from 'utils/googleCalendar/generateUrlIframe';
import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';
import { addHoursAndMinutesInDate } from 'utils/date-time.utils';
import { KeyboardDatePicker } from 'components/DatePickers';
import { useCustomerConfig } from 'hooks/useCustomerConfig';
import ModalAddCertificates from '../ModalAddCertificates';

const CssTextField = withStyles(() => ({
  root: {
    backgroundColor: '#fff',
    borderRadius: 5,
    '& label.Mui-focused': {
      color: '#000',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#fff',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#000',
      },
      '&:hover fieldset': {
        borderColor: '#000',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#000',
      },
    },
  },
}))(TextField);

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
  btn: {
    color: '#fff',
    height: '100%',
    backgroundColor: colors.greenLight,
    '&:hover': {
      backgroundColor: colors.greenLight,
    },
  },
}));

const Form = props => {
  const { enableHearingForJuvenileOffenders } = useCustomerConfig();
  const { params, setParams, prosecutor, setOpenHearingModal, hasArchivingTask, inquiryInvestigatedId } = props;

  const intimationOptions = [
    {
      name: `Audiência${enableHearingForJuvenileOffenders ? '/Oitiva' : ''}`,
      value: 'HEARING',
    },
    hasArchivingTask && {
      name: 'Arquivamento',
      value: 'ARCHIVE',
    },
  ];

  const hearingTypes = [
    {
      name: 'Remota',
      value: false,
    },
    {
      name: 'Pessoalmente',
      value: true,
    },
  ];

  const classes = useStyles();

  const initialState = {
    typeIntimation: 'HEARING',
    //intimationDeadline: '',
    hearingDate: '',
    hourHearing: '',
    isInPerson: false,
  };

  const [formState, setFormState] = useState({
    isValid: false,
    values: { ...initialState },
    touched: {},
    errors: {},
  });

  const [openSchedule, setOpenSchedule] = useState(false);
  const [url, setUrl] = useState('');
  const [openAddCertificates, setOpenAddCertificates] = useState(false);

  useEffect(() => {
    if (!params) {
      setFormState(formState => ({
        ...formState,
        values: { ...initialState },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      },
    }));
  };

  const handleSubmit = event => {
    event.preventDefault();

    const {
      typeIntimation,
      /*intimationDeadline,*/ hearingDate,
      hourHearing,
      isInPerson,
    } = formState.values;

    if (typeIntimation === '' || !typeIntimation) {
      toast.warn('Informe um tipo de intimação');
      return;
    }

    if (!hearingDate) {
      toast.warn('Informe a data da intimação');
      return;
    }

    if (!hourHearing || hourHearing.length !== 5) {
      toast.warn('Informe o horário da intimação 00:00');
      return;
    }

    let hora = hourHearing.substring(0, 2);
    let min = hourHearing.substring(3, 5);

    let manipulateHearingDate = addHoursAndMinutesInDate(hearingDate, hora, min);

    let params = {
      hearingDate: manipulateHearingDate,
      isInPerson: isInPerson,
    };

    setParams(params);
    setOpenHearingModal(true);
  };

  const handleOpenSchedule = () => {
    if (prosecutor) {
      setUrl(generateUrl(prosecutor.email));
      setOpenSchedule(true);
    } else {
      toast.warn('Não há promotor vinculado a este inquérito');
      return;
    }
  };

  return (
    <Box className={classes.root}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <CssTextField
              fullWidth
              select
              size="small"
              variant="filled"
              label="Tipo da intimação"
              name="typeIntimation"
              onChange={handleChange}
              value={formState.values.typeIntimation || ''}
            >
              {intimationOptions.map(item => (
                <MenuItem key={item.name} value={item.value}>
                  {item.name}
                </MenuItem>
              ))}
            </CssTextField>
          </Grid>
          {formState.values.typeIntimation === 'ARCHIVE' ? (
            <Grid item xl={5} lg={5} md={6} sm={12} xs={12}>
            <Button variant="contained" fullWidth className={classes.btn} onClick={() => setOpenAddCertificates(true)}>
              Adicionar Certidões
            </Button>
          </Grid>
          ) : (
            <>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <KeyboardDatePicker
                  variant="inline"
                  inputVariant="filled"
                  name="hearingDate"
                  label={`Data da audiência${enableHearingForJuvenileOffenders ? '/oitiva' : ''}`}
                  value={formState.values.hearingDate || null}
                  minDate={new Date()}
                  size="small"
                  onChange={newDate =>
                    setFormState(formState => ({
                      ...formState,
                      values: {
                        ...formState.values,
                        hearingDate: newDate,
                      },
                    }))
                  }
                  component={CssTextField}
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <InputMask
                  onChange={handleChange}
                  value={formState.values.hourHearing || ''}
                  mask="99:99"
                  maskChar={null}
                >
                  {inputProps => (
                    <CssTextField
                      {...inputProps}
                      label="Hora"
                      name="hourHearing"
                      variant="filled"
                      fullWidth
                      size="small"
                    />
                  )}
                </InputMask>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <CssTextField
                  label={`Formato da audiência${
                    enableHearingForJuvenileOffenders ? '/oitiva' : ''
                  }`}
                  select
                  name="isInPerson"
                  variant="filled"
                  size="small"
                  value={formState.values.isInPerson}
                  onChange={handleChange}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  {hearingTypes.map(item => (
                    <MenuItem key={item.name} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
                </CssTextField>
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                <Button variant="contained" fullWidth className={classes.btn} type="submit">
                  Intimar
                </Button>
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                <Tooltip title="Consultar Agenda do promotor" placement="bottom">
                  <Button
                    variant="contained"
                    fullWidth
                    className={classes.btn}
                    startIcon={<DateRangeIcon style={{ color: '#fff' }} />}
                    onClick={handleOpenSchedule}
                  >
                    Agenda
                  </Button>
                </Tooltip>
              </Grid>
            </>
          )}
        </Grid>
      </form>
      <ModalSchedule
        open={openSchedule}
        onClose={() => setOpenSchedule(false)}
        url={url}
        formState={formState}
        setFormState={setFormState}
        />
      <ModalAddCertificates
        open={openAddCertificates}
        onClose={() => setOpenAddCertificates(false)}
        inquiryInvestigatedId={inquiryInvestigatedId}
      />
    </Box>
  );
};

export default Form;

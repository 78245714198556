export const CUSTOMER_CONFIG = {
  INQUIRY_MANUAL_REGISTRATION: 'inquiryManualRegistration',
  INQUIRY_IMPORT: 'inquiryImport',
  INQUIRY_DISPLAY_CRIMES: 'inquiryDisplayCrimes',
  INQUIRY_DISPLAY_SUBJECTS: 'inquiryDisplaySubjects',
  DISPLAY_TRIAGE_STEP: 'displayTriageStep',
  DISPLAY_DISTRIBUTION_STEP: 'displayDistributionStep',
  ENABLE_FORWARD_BUTTON: 'enableForwardButton',
  VICTIM_INTIMATION: 'victimIntimation',
  ALL_ORIGIN_NUMBERS_ARE_NPU: 'allOriginNumbersAreNpu',
  ALLOW_MANUAL_HML: 'allowManualHml',
  AUTOMATIC_MOVEMENTS: 'automaticMovements',
  ENABLE_REDISTRIBUTION_BUTTON: 'enableRedistributionButton',
  ENABLE_HEARING_FOR_JUVENILE_OFFENDERS: 'enableHearingForJuvenileOffenders',
  VIEW_PCPE_INTEGRATION_REPORT: 'viewPcpeIntegrationReport',
  VIEW_PROCEDURE_AUDIT_REPORT: 'viewProcedureAuditReport',
  VIEW_USER_AUDIT_REPORT: 'viewUserAuditReport',
  ALLOW_CANCEL_AGREEMENT: 'allowCancelAgreement',
  ENABLE_PARTNER_ASSOCIATION_REGISTRATION: 'enablePartnerAssociationRegistration',
  ENABLE_SEMA_AND_CEMA_ASSOCIATION_REGISTRATION: 'enableSemaAndCemaAssociationRegistration',
  ALLOW_MANUAL_SYNCHRONIZATION_OF_INVESTIGATED: 'allowManualSynchronizationOfInvestigated',
  VIEW_MODAL_BALANCES: 'viewModalBalances',
  VIEW_MPPE_INTEGRATIONS_IN_MODAL_VERSION: 'viewMppeIntegrationsInModalVersion',
  INQUIRY_DISPLAY_INTERNAL_SYSTEM_NUMBER: 'inquiryDisplayInternalSystemNumber',
  INQUIRY_DISPLAY_CRIMINAL_RECORD_ITEM: 'inquiryDisplayCriminalRecordItem',
  SHOW_GENERAL_PENALTIES: 'showGeneralPenalties',
  INQUIRY_DISPLAY_BOUND_DOCUMENTS: 'inquiryDisplayBoundDocuments',
  INQUIRY_DISPLAY_BOUND_INQUIRY: 'inquiryDisplayBoundInquiry',
  DOWNLOAD_SPLIT_DOCUMENT: 'downloadSplitDocument',
  INQUIRY_DISPLAY_SUSPECT_BUTTON: 'inquiryDisplaySuspectButton',
  IS_MPDFT: 'isMPDFT',
  SHOW_SOCIOEDUCATIONAL_LECTURE_PENALTIES: 'showSocioEducationalLecturePenalties',
  ENABLE_PENALTY_TYPES: 'enablePenaltyTypes',
  ENABLE_DOC_AND_DOCX_VIEWER: 'enableDocAndDocxViewer',
  ENABLE_AUDIT_LOG_VIEW: 'enableAuditLogView',
  ENABLE_REGISTER_AGREEMENT_FROM_INTIMATION: 'enableRegisterAgreementFromIntimation',
  ENABLE_ERROR_IMPORT_ARQUIMEDES_INTEGRATION_REPORT_VIEW:
    'enableErrorImportArquimedesIntegrationReportView',
  ENABLE_INQUIRIES_MIGRATION_REPORT: 'enableInquiriesMigrationReport',
  ENABLE_MIGRATED_INQUIRY: 'enableMigratedInquiry',
  ENABLE_PRE_REGISTER_PJE: 'enablePreRegisterPje',
  ENABLE_BACK_TO_PREVIOUS_STEP: 'enableBackToPreviousStep',
  CONNECT_WITH_AI_SERVICE: 'connectWithAIService',
  ENABLE_INSTALLMENT_TYPES: 'enableInstallmentTypes',
  SHODO_API_BASE_URL: 'shodoApiBaseURL',
  ENABLE_CHANGE_PENALTIES_VIEW: 'enableChangePenaltiesView',
  ENABLE_PROJECT_PENALTIES_LIST: 'enableProjectPenaltiesList',
  SHOW_MATERIALS_TAB: 'showMaterialsTab',
  ENABLE_SEMA_SERVER_ACTIVITIES_VIEW: 'enableSemaServerActivitiesView',
  ENABLE_SEI_PROJECT_SERVICE: 'enableSeiProjectService',
  ENABLE_PJE_INTEGRATION_GROUPS: 'enablePjeIntegrationGroups',
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Box,
  TextareaAutosize,
  Typography,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  CurrencyTextField,
  InputConsensus,
  ButtonConsensus,
  KeyboardDatePicker,
  ModalConfirm,
  InstallmentTypeRadioGroup,
  InstallmentFixedPerPeriod,
  InstallmentVariablePerPeriod,
} from 'components';
import { colors } from 'utils/colorsSteps';
import { Autocomplete, Skeleton } from '@material-ui/lab';
import { toast } from 'react-toastify';
import { formatDateToISO8601 } from 'utils/date-time.utils';
import associationService from 'services/association/associationService';
import CircularProgress from '@material-ui/core/CircularProgress';
import debounce from 'lodash.debounce';
import moment from 'moment';
import { currencyFormat, getOnlyNumbers } from 'utils/currency';
import { orderParcelsByInitialDate, parcelsSum } from 'utils/parcels';
import { getProjects } from '../utils';
import { PARTNER_SITUATIONS } from 'constants/partner-situations';
import { useCustomerConfig } from 'hooks/useCustomerConfig';
import agreementService from 'services/agreement/agreementService';
import { INSTALLMENT_TYPES } from 'constants/agreement/installmentTypes';
import InstallmentPaidBySpecificDate from 'components/InstallmentPaidBySpecificDate';

const useStyles = makeStyles(theme => ({
  containerForm: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  containerParcels: {
    padding: theme.spacing(2),
    backgroundColor: '#ececec',
    borderRadius: '0.7rem',
    margin: theme.spacing(0, 0.8, 1, 0.8),
  },
}));

const PecuniaryPenalties = props => {
  const {
    setDisabledActionsPenaltyTable = {},
    selectedIndex = null,
    setSelectedIndex = {},
    pecuniary,
    setPecuniary,
    description,
    setDescription,
    institutionType,
    institutionSelected,
  } = props;
  const classes = useStyles();

  const { isMPDFT, enablePenaltyTypes, enableInstallmentTypes } = useCustomerConfig();

  const [row, setRow] = useState(null);
  const [showCompany, setShowCompany] = useState(false);
  const [companys, setCompanys] = useState([]);

  const [showType, setShowType] = useState(false);
  const [types, setTypes] = useState([]);
  const [loadingTypes, setLoadingTypes] = useState(false);

  const [listProjects, setListProjects] = useState([]);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [loadingAssociations, setLoadingAssociations] = useState(false);
  const [inputSearchProjects, setInputSearchProjects] = useState('');
  const [pageProjects, setPageProjects] = useState(1);
  const [lastPageProjects, setLastPageProjects] = useState(1);

  const [installmentType, setInstallmentType] = useState(
    row?.installmentType || INSTALLMENT_TYPES.FIXED_PER_PERIOD,
  );

  const [openDeleteParcelModalConfirm, setOpenDeleteParcelModalConfirm] = useState(false);
  const [parcelToDelete, setParcelToDelete] = useState(null);

  const initialValues = {
    paymentDeadline: null,
    value: 0,
    penalty: 'Pecúnia',
    description: '',
    descriptionLocal: isMPDFT ? '' : description,
    association: null,
    parcels: [],
    project: null,
    numberOfParcels: 1,
    type: null,
    periodicy: '',
    totalValue: 0,
    minimumValue: null,
    maximumValue: null,
    initialDate: null,
  };

  const [formState, setFormState] = useState({
    isValid: false,
    values: { ...initialValues },
    touched: {},
    errors: {},
  });

  useEffect(() => {
    setFormState(formState => ({
      ...formState,
      values:
        selectedIndex !== null
          ? {
              ...pecuniary[selectedIndex.index],
              descriptionLocal: isMPDFT ? pecuniary[selectedIndex.index].description : description,
            }
          : { ...initialValues },
    }));
    selectedIndex && setInstallmentType(pecuniary[selectedIndex.index]?.installmentType);
  }, [selectedIndex]);

  const handleChangeCompany = (_event, newValue) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        association: newValue,
        project: null,
      },
    }));
  };

  const handleChangeType = (_event, newValue) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        type: newValue,
      },
    }));
  };

  const getCompanys = async () => {
    try {
      setLoadingAssociations(true);

      const params = {
        forward: false,
        includeAssociationsWithNullSituation: true,
      };

      if (isMPDFT) {
        delete params.includeAssociationsWithNullSituation;
        params.situation = PARTNER_SITUATIONS.ACCREDITED;
      }

      await associationService
        .getAssociationsService(params)
        .then(response => {
          setCompanys(response.data.data);
        })
        .finally(() => {
          setShowCompany(true);
        });
    } catch (err) {
      toast.error('Erro ao buscar instituições');
    } finally {
      setLoadingAssociations(false);
    }
  };

  const getTypes = async () => {
    try {
      setLoadingTypes(true);

      await agreementService
        .getPenaltyTypes()
        .then(response => {
          setTypes(response.data.pecuniaryTypes);
        })
        .finally(() => {
          setShowType(true);
        });
    } catch (err) {
      toast.error('Erro ao buscar tipos');
    } finally {
      setLoadingTypes(false);
    }
  };

  useEffect(() => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        descriptionLocal: description,
      },
    }));
  }, [description]);

  const handleChangeCurrency = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: getOnlyNumbers(event.target.value),
      },
    }));
  };

  const handleInputKeyPress = event => {
    if (event.key === ',' || event.key === '.') {
      event.preventDefault();
    }
  };

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
    if (event.target.name === 'descriptionLocal') {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          description: event.target.value,
        },
      }));
    }
  };

  const handleClearFormStat = () => {
    setRow(null);
    if (selectedIndex !== null) {
      setDisabledActionsPenaltyTable(prevState => ({
        ...prevState,
        [selectedIndex.penaltiesIndex]: { ...prevState[selectedIndex.penaltiesIndex], edit: false },
      }));
      setSelectedIndex(null);
    }
    setFormState(formState => ({
      ...formState,
      values: { ...initialValues },
    }));
  };

  useEffect(() => {
    if (row) {
      setFormState(formState => ({
        ...formState,
        values: { ...row, descriptionLocal: isMPDFT ? row.description : description },
      }));
    }
    getCompanys();
    enablePenaltyTypes && getTypes();
    enableInstallmentTypes &&
      setInstallmentType(row?.installmentType || INSTALLMENT_TYPES.FIXED_PER_PERIOD);
  }, [row, institutionSelected]);

  const handleSubmit = event => {
    event.preventDefault();
    const { paymentDeadline, descriptionLocal, description, parcels, association, type } = formState.values;

    if (
      (!enableInstallmentTypes &&
        (parcels.length === 0 || parcels.some(parcel => !parcel.value || parcel.value === 0))) ||
      (isMPDFT ? description === '' : descriptionLocal === '') ||
      (institutionType === 'final' && !association) ||
      (enablePenaltyTypes && !type)
    ) {
      toast.warn('Preencha os campos obrigatórios');
      return;
    }
    if (selectedIndex !== null) {
      if (
        paymentDeadline &&
        pecuniary.find(value => value.paymentDeadline === paymentDeadline && value.value === value)
      ) {
        toast.warn('Data e valor já inseridos');
        return;
      }

      let newArray = [...pecuniary];
      let newPecuniary = formState.values;
      setDescription(newPecuniary.descriptionLocal);
      delete newPecuniary.descriptionLocal;
      newArray[selectedIndex.index] = newPecuniary;
      setPecuniary(newArray);
    } else {
      if (
        paymentDeadline &&
        pecuniary.find(value => value.paymentDeadline === paymentDeadline && value.value === value)
      ) {
        toast.warn('Data e valor já inseridos');
        return;
      }
      let newPecuniary = formState.values;
      setDescription(newPecuniary.descriptionLocal);
      delete newPecuniary.descriptionLocal;
      setPecuniary([...pecuniary, newPecuniary]);
    }

    handleClearFormStat();
  };

  const handleAddParcel = () => {
    const { paymentDeadline, value, numberOfParcels } = formState.values;

    if (!value || value == '0') {
      toast.warn('Insira um valor');
      return;
    }
    if (!numberOfParcels) {
      toast.warn('Insira a quantidade de parcelas');
      return;
    }

    const newParcels = [];
    let date = paymentDeadline && moment(paymentDeadline);

    for (let i = 0; i < numberOfParcels; i++) {
      newParcels.push({
        date: date && formatDateToISO8601(date.clone().add(i, 'months')),
        value: value,
      });
    }

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        parcels: newParcels,
        paymentDeadline: '',
        value: 0,
      },
    }));
  };

  const loadMoreProjectsResults = () => {
    const nextPage = pageProjects + 1;

    setPageProjects(nextPage);

    setLoadingProjects(true);

    const params = {
      page: nextPage,
      size: 20,
      name: inputSearchProjects,
    };

    if (formState.values.association?.id) {
      params.associationId = formState.values.association?.id;
    }

    associationService
      .getAssociationsService(params)
      .then(response => {
        setListProjects([...listProjects, ...response.data.data]);
        setLastPageProjects(response.data.lastPage);
      })
      .finally(() => setLoadingProjects(false));
  };

  const handleScrollProjects = event => {
    const listboxNode = event.currentTarget;

    const position = listboxNode.scrollTop + listboxNode.clientHeight;

    if (
      listboxNode.scrollHeight - position <= 1 &&
      pageProjects < lastPageProjects &&
      !loadingProjects
    ) {
      loadMoreProjectsResults();
    }
  };

  const debouncedGetProjects = useCallback(
    debounce(
      (newValue, associationId) =>
        getProjects({
          setLoading: setLoadingProjects,
          setPage: setPageProjects,
          setLastPage: setLastPageProjects,
          setProjects: setListProjects,
          input: newValue,
          associationId,
        }),
      500,
    ),
    [],
  );

  const updateValueProject = newValue => {
    if (!listProjects.find(inst => inst.name === newValue)) {
      debouncedGetProjects(newValue, formState.values.association?.id);
      setInputSearchProjects(newValue);
    }
  };

  const handleChangeProject = (_event, newValue) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        project: newValue,
      },
    }));
  };

  const handleParcelDateChange = (index, newDate, isInitial = false) => {
    const updatedParcels = formState.values.parcels.map((parcel, idx) =>
      idx === index
        ? isInitial
          ? { ...parcel, initialDate: newDate }
          : { ...parcel, date: newDate }
        : parcel,
    );
    setFormState({
      ...formState,
      values: {
        ...formState.values,
        parcels: updatedParcels,
      },
    });
  };

  const handleParcelValueChange = (index, event) => {
    const updatedParcels = formState.values.parcels.map((parcel, idx) =>
      idx === index
        ? { ...parcel, [event.target.name]: getOnlyNumbers(event.target.value) }
        : parcel,
    );
    setFormState({
      ...formState,
      values: {
        ...formState.values,
        parcels: updatedParcels,
      },
    });
  };

  const handleOpenDeleteParcelModalConfirm = parcel => {
    setOpenDeleteParcelModalConfirm(true);
    setParcelToDelete(parcel);
  };

  const handleCloseDeleteParcelModalConfirm = () => {
    setOpenDeleteParcelModalConfirm(false);
    setParcelToDelete(null);
  };

  const deleteParcel = () => {
    const parcel = parcelToDelete;
    const index = formState.values.parcels.findIndex(item => item.date === parcel.date);
    if (index !== -1) {
      const newParcels = formState.values.parcels.filter(
        (value, _index, _arr) => value.date !== parcel.date,
      );
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          parcels: newParcels,
        },
      }));
    }
  };

  useEffect(() => {
    debouncedGetProjects('', formState.values.association?.id);
  }, [formState.values.association]);

  const handleToRead = () => {
    handleClearFormStat();
  };

  return (
    <Box className={classes.container}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} className={classes.containerForm}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            {showType && !loadingTypes && enablePenaltyTypes && (
              <Autocomplete
                options={types}
                fullWidth
                autoHighlight
                onChange={handleChangeType}
                noOptionsText="Não encontrado"
                value={formState.values.type}
                getOptionLabel={option => option || ''}
                renderOption={option => <React.Fragment>{option || ''}</React.Fragment>}
                renderInput={params => (
                  <InputConsensus
                    {...params}
                    label="Tipo*"
                    variant="standard"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            {showCompany && institutionType === 'final' && !loadingAssociations ? (
              <Autocomplete
                options={companys}
                fullWidth
                autoHighlight
                onChange={handleChangeCompany}
                noOptionsText="Não encontrado"
                value={formState.values.association}
                getOptionLabel={option => option.name || ''}
                renderOption={option => <React.Fragment>{option.name || ''}</React.Fragment>}
                renderInput={params => (
                  <InputConsensus
                    {...params}
                    label="Instituição*"
                    variant="standard"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            ) : (
              institutionType === 'final' && <Skeleton variant="rect" animation="pulse" />
            )}
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            {showCompany && institutionType === 'final' && formState.values.association?.id && (
              <Autocomplete
                value={formState.values.project}
                getOptionSelected={(option, value) => option.name === value.name}
                getOptionLabel={option => option.name}
                filterOptions={x => x}
                onInputChange={(_, newInputValue) => updateValueProject(newInputValue)}
                fullWidth
                noOptionsText="Nada foi encontrado"
                loadingText="Buscando..."
                loading={loadingProjects}
                clearOnBlur={false}
                options={listProjects}
                onChange={handleChangeProject}
                renderOption={option => <>{option.name}</>}
                renderInput={params => (
                  <InputConsensus
                    {...params}
                    name="project"
                    label="Projeto"
                    variant="standard"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingProjects ? <CircularProgress color="primary" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                ListboxProps={{
                  onScroll: handleScrollProjects,
                }}
              />
            )}
          </Grid>

          <Grid
            item
            container
            spacing={2}
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={classes.containerParcels}
          >
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography align="left" variant="h5" className={classes.form}>
                Parcelas
              </Typography>
            </Grid>

            {formState.values.parcels.length === 0 && enableInstallmentTypes && (
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <InstallmentTypeRadioGroup
                  installmentType={installmentType}
                  setInstallmentType={setInstallmentType}
                />
              </Grid>
            )}

            {enableInstallmentTypes ? (
              <>
                {installmentType === INSTALLMENT_TYPES.FIXED_PER_PERIOD && (
                  <InstallmentFixedPerPeriod
                    formState={formState}
                    setFormState={setFormState}
                    handleChange={handleChange}
                    handleChangeCurrency={handleChangeCurrency}
                    handleInputKeyPress={handleInputKeyPress}
                    handleOpenDeleteParcelModalConfirm={handleOpenDeleteParcelModalConfirm}
                    handleParcelValueChange={handleParcelValueChange}
                    handleParcelDateChange={handleParcelDateChange}
                    selectedIndex={selectedIndex}
                  />
                )}
                {installmentType === INSTALLMENT_TYPES.VARIABLE_PER_PERIOD && (
                  <InstallmentVariablePerPeriod
                    formState={formState}
                    setFormState={setFormState}
                    handleChange={handleChange}
                    handleChangeCurrency={handleChangeCurrency}
                    handleInputKeyPress={handleInputKeyPress}
                    handleOpenDeleteParcelModalConfirm={handleOpenDeleteParcelModalConfirm}
                    handleParcelValueChange={handleParcelValueChange}
                    handleParcelDateChange={handleParcelDateChange}
                    selectedIndex={selectedIndex}
                  />
                )}
                {installmentType === INSTALLMENT_TYPES.PAID_BY_SPECIFIC_DATE && (
                  <InstallmentPaidBySpecificDate
                    formState={formState}
                    setFormState={setFormState}
                    handleChangeCurrency={handleChangeCurrency}
                    handleOpenDeleteParcelModalConfirm={handleOpenDeleteParcelModalConfirm}
                    handleParcelValueChange={handleParcelValueChange}
                    handleParcelDateChange={handleParcelDateChange}
                    selectedIndex={selectedIndex}
                  />
                )}
              </>
            ) : (
              <>
                {formState.values.parcels.length === 0 && (
                  <>
                    <Grid item xl={5} lg={5} md={5} sm={5} xs={12}>
                      <KeyboardDatePicker
                        variant="inline"
                        name="paymentDeadline"
                        label="Data inicial"
                        value={formState.values.paymentDeadline || null}
                        minDate={!selectedIndex ? new Date() : undefined}
                        onChange={newDate =>
                          setFormState(formState => ({
                            ...formState,
                            values: {
                              ...formState.values,
                              paymentDeadline: newDate,
                            },
                          }))
                        }
                      />
                    </Grid>
                    <Grid item xl={5} lg={5} md={5} sm={5} xs={12}>
                      <CurrencyTextField
                        label="Valor por mês*"
                        name="value"
                        onChange={handleChangeCurrency}
                        value={formState.values.value || 0}
                        fullWidth
                      />
                    </Grid>
                  </>
                )}
                {formState.values.parcels && formState.values.parcels.length === 0 && (
                  <>
                    <Grid item xl={2} lg={2} md={2} sm={2} xs={12}>
                      <InputConsensus
                        label="Quantidade de parcelas*"
                        name="numberOfParcels"
                        variant="standard"
                        type="number"
                        inputProps={{
                          min: 1,
                        }}
                        onChange={handleChange}
                        onKeyPress={handleInputKeyPress}
                        value={formState.values.numberOfParcels || 1}
                        fullWidth
                      />
                    </Grid>
                    <Grid
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <ButtonConsensus
                        title="Gerar Parcelas"
                        backgroundColor={colors.primary}
                        hovercolor={colors.primary}
                        onClick={() => handleAddParcel()}
                        size="medium"
                      />
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
          {!enableInstallmentTypes && (
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Data</TableCell>
                      <TableCell align="center">Valor</TableCell>
                      <TableCell align="center" width={150}>
                        <Box display="flex" position="relative" justifyContent="center">
                          Ações
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderParcelsByInitialDate(formState.values.parcels).map((item, index) => (
                      <TableRow key={index} hover>
                        <TableCell component="th" scope="row" align="center">
                          <KeyboardDatePicker
                            variant="inline"
                            name="paymentDeadline"
                            value={item.date || null}
                            onChange={newDate => handleParcelDateChange(index, newDate)}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          <CurrencyTextField
                            name="value"
                            onKeyPress={handleInputKeyPress}
                            onChange={e => handleParcelValueChange(index, e)}
                            value={item.value / 100 || 0}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            color="primary"
                            size="small"
                            onClick={() => handleOpenDeleteParcelModalConfirm(item)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell align="left" colSpan={3} style={{ fontWeight: 'bold' }}>
                        Total: {currencyFormat(parcelsSum(formState.values.parcels))}
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Grid>
          )}
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextareaAutosize
              style={{ width: '100%', padding: 5, resize: 'none' }}
              maxRows={4}
              minRows={4}
              aria-label="maximum height"
              placeholder="Descrever como será cumprido *"
              onChange={handleChange}
              name="descriptionLocal"
              value={isMPDFT ? formState.values.description : formState.values.descriptionLocal}
            />
          </Grid>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <ButtonConsensus
              title={`${selectedIndex !== null ? 'Alterar' : 'Incluir'} ${
                isMPDFT ? 'Medida' : 'Penalidade'
              }`}
              backgroundColor={colors.primary}
              hovercolor={colors.primary}
              type="submit"
              size="medium"
            />
            <ButtonConsensus
              title={`${selectedIndex !== null ? 'Cancelar' : 'Limpar'}`}
              backgroundColor={colors.primary}
              hovercolor={colors.primary}
              onClick={() => handleToRead()}
              size="medium"
              style={{ marginLeft: '-6px' }}
            />
          </Grid>
        </Grid>
      </form>
      {openDeleteParcelModalConfirm && (
        <ModalConfirm
          open={openDeleteParcelModalConfirm}
          handleClose={handleCloseDeleteParcelModalConfirm}
          onClick={deleteParcel}
          title="Excluir parcela"
        />
      )}
    </Box>
  );
};

export default PecuniaryPenalties;

import React from 'react';
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  IconButton,
} from '@material-ui/core';
import ButtonConsensus from 'components/ButtonConsensus';
import { colors } from 'utils/colorsSteps';
import CurrencyTextField from 'components/CurrencyTextField';
import { KeyboardDatePicker } from 'components/DatePickers';
import { toast } from 'react-toastify';
import moment from 'moment';
import { orderParcelsByInitialDate } from 'utils/parcels';
import { currencyFormat } from 'utils/currency';
import DeleteIcon from '@material-ui/icons/Delete';
import { INSTALLMENT_TYPES } from 'constants/agreement/installmentTypes';

const InstallmentPaidBySpecificDate = props => {
  const {
    formState,
    setFormState,
    handleChangeCurrency,
    handleOpenDeleteParcelModalConfirm,
    handleParcelValueChange,
    handleParcelDateChange,
    selectedIndex,
  } = props;

  const handleAddParcel = () => {
    const {
      paymentDeadline,
      totalValue,
      minimumValue,
      maximumValue,
      initialDate,
    } = formState.values;

    if (!isValidParcel()) {
      return;
    }

    const newParcels = [];

    newParcels.push({
      date: paymentDeadline && moment(paymentDeadline),
      initialDate: initialDate && moment(initialDate),
      minimumValue,
      maximumValue,
    });

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        parcels: newParcels,
        paymentDeadline: '',
        finalDate: '',
        totalValue,
        minimumValue: null,
        maximumValue: null,
        installmentType: INSTALLMENT_TYPES.PAID_BY_SPECIFIC_DATE,
        initialDate: '',
      },
    }));
  };

  const isValidParcel = () => {
    const { paymentDeadline, totalValue, initialDate } = formState.values;

    let isValid = true;

    if (!totalValue || totalValue == 0) {
      toast.warn('Insira o valor total');
      isValid = false;
    }
    if (!initialDate) {
      toast.warn('Selecione a data inicial');
      isValid = false;
    }
    if (!paymentDeadline) {
      toast.warn('Selecione a data final');
      isValid = false;
    }
    if (initialDate >= paymentDeadline) {
      toast.warn('A data final deve ser maior que a data inicial');
      isValid = false;
    }

    return isValid;
  };

  return (
    <>
      {formState.values.parcels.length === 0 && (
        <>
          <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
            <CurrencyTextField
              label="Valor total a pagar*"
              name="totalValue"
              onChange={handleChangeCurrency}
              value={formState.values.totalValue / 100 || 0}
              fullWidth
            />
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
            <KeyboardDatePicker
              variant="inline"
              name="paymentDeadline"
              label="Até a data*"
              value={formState.values.paymentDeadline || null}
              minDate={!selectedIndex ? new Date() : undefined}
              onChange={newDate =>
                setFormState(formState => ({
                  ...formState,
                  values: {
                    ...formState.values,
                    paymentDeadline: newDate,
                  },
                }))
              }
            />
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
            <KeyboardDatePicker
              variant="inline"
              name="paymentDeadline"
              label="Data inicial*"
              value={formState.values.initialDate || null}
              minDate={!selectedIndex ? new Date() : undefined}
              onChange={newDate =>
                setFormState(formState => ({
                  ...formState,
                  values: {
                    ...formState.values,
                    initialDate: newDate,
                  },
                }))
              }
            />
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
            <CurrencyTextField
              label="Valor mínimo por mês"
              name="minimumValue"
              onChange={handleChangeCurrency}
              value={formState.values.minimumValue ?? null}
              allowNullValue={true}
              fullWidth
            />
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
            <CurrencyTextField
              label="Valor máximo por mês"
              name="maximumValue"
              onChange={handleChangeCurrency}
              value={formState.values.maximumValue ?? null}
              allowNullValue={true}
              fullWidth
            />
          </Grid>
        </>
      )}
      {formState.values.parcels && formState.values.parcels.length === 0 && (
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <ButtonConsensus
            title="Gerar Parcela"
            backgroundColor={colors.primary}
            hovercolor={colors.primary}
            onClick={() => handleAddParcel()}
            size="medium"
          />
        </Grid>
      )}
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Data Final</TableCell>
                <TableCell align="center">Data Inicial</TableCell>
                <TableCell align="center">Valor Mínimo Mensal</TableCell>
                <TableCell align="center">Valor Máximo Mensal</TableCell>
                <TableCell align="center" width={150}>
                  <Box display="flex" position="relative" justifyContent="center">
                    Ações
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderParcelsByInitialDate(formState.values.parcels).map((item, index) => (
                <TableRow key={index} hover>
                  <TableCell component="th" scope="row" align="center">
                    <KeyboardDatePicker
                      variant="inline"
                      name="paymentDeadline"
                      value={item.date || null}
                      onChange={newDate => handleParcelDateChange(index, newDate)}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    <KeyboardDatePicker
                      variant="inline"
                      name="initialDate"
                      value={item.initialDate || null}
                      onChange={newDate => handleParcelDateChange(index, newDate, true)}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    <CurrencyTextField
                      name="minimumValue"
                      onChange={e => handleParcelValueChange(index, e)}
                      value={item.minimumValue != null ? item.minimumValue / 100 : null}
                      allowNullValue={true}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    <CurrencyTextField
                      name="maximumValue"
                      onChange={e => handleParcelValueChange(index, e)}
                      value={item.maximumValue != null ? item.maximumValue / 100 : null}
                      allowNullValue={true}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={() => handleOpenDeleteParcelModalConfirm(item)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell align="left" colSpan={3} style={{ fontWeight: 'bold' }}>
                  Valor Total: {currencyFormat(formState.values.totalValue / 100)}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};

export default InstallmentPaidBySpecificDate;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useLayoutEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import ViewCardsInquiries from '../ViewCardsInquiries';
import {
  Grid,
  Card,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Box,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import InputMask from 'react-input-mask';
import MsgReturnSearch from './components/MsgReturnSearch';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '@material-ui/lab/Pagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../../font.css';
import { genericInquiry, npuGenericInquiry, pcpeGenericInquiry } from 'masks/masks';
import { clearInquiryNumber } from 'utils/genericFunctions/formatedInquiryNumber';
import { useFilterInquiry } from 'hooks/useFilterInquiry';
import pmService from 'services/PM/pmService';
import associationService from 'services/association/associationService';
import crimeService from 'services/crime/crimeService';
import inquiryService from 'services/inquiries/inquiryService';
import userService from 'services/user/userService';
import {
  addHoursMinutesSecondsToFinalDatesFilter,
  addHoursMinutesSecondsToStartDatesFilter,
  formatDateToISO8601,
  formatDateToISOString,
} from 'utils/date-time.utils';
import debounce from 'lodash.debounce';
import { Autocomplete } from '@material-ui/lab';
import { useUserTypes } from 'hooks/useUserTypes';
import { listSteps, STEPS_INQUIRY } from 'constants/inquirys/stepsInquiry';
import CloseIcon from '@material-ui/icons/Close';
import ModalClasses from 'components/DivNumeroRequerimento/components/ModalClasses';
import { AGREEMENT_STATUS } from 'constants/agreement/agreementStatus';
import { SiMicrosoftexcel } from 'react-icons/si';
import { GENERIC_DEADLINE } from 'constants/tasksDeadline';
import { useCustomerConfig } from 'hooks/useCustomerConfig';
import lawService from 'services/law/lawService';
import { CUSTOMER_CONFIG } from 'constants/customerConfig';
import { CustomerDisplayValidator, InputConsensus } from 'components';
import { setAiMessages, setChatAiFeature, setChatAiSituation } from 'actions';
import { chatAiPossibleStates } from 'reducers/chatAiReducer';
import { CHAT_AI_FEATURES, CHAT_AI_SENDERS, CHAT_AI_TEXT_TYPES } from 'constants/ai/chatAiFeatures';
import * as _ from 'lodash';

const FilterInquiry = props => {
  const { enableHearingForJuvenileOffenders } = useCustomerConfig();
  const {
    details,
    searchDistribution,
    screeningNotPursuit,
    analyze,
    intimation,
    statusInquiries,
    hearing,
    processing,
    agreement,
    linking,
    idLinkingInquiry,
    forwardingTerm,
    diligence,
    agreementPendency,
    migration,
  } = props;

  const {
    prosecutorOfficeIdContext,
    setProsecutorOfficeIdContext,
    prosecutorContext,
    setProsecutorContext,
    inquiryStepContext,
    setInquiryStepContext,
    policeInquiryNumberContext,
    setPoliceInquiryNumberContext,
    internalSystemNumberContext,
    setInternalSystemNumberContext,
    investigatedNameContext,
    setInvestigatedNameContext,
    investigatedCpfContext,
    setInvestigatedCpfContext,
    startDateContext,
    setStartDateContext,
    endDateContext,
    setEndDateContext,
    crimeNomeContext,
    crimeIdContext,
    excludePersecutionContext,
    setExcludePersecutionContext,
    excludeNonPersecutionContext,
    setExcludeNonPersecutionContext,
    inquiryStatusContext,
    setInquiryStatusContext,
    onlyConcludedIn24hContext,
    setOnlyConcludedIn24hContext,
    isArrestedContext,
    setIsArrestedContext,
    awaitingInstitutionResponseContext,
    setAwaitingInstitutionResponseContext,
    isFlagrantContext,
    setIsFlagrantContext,
    priorityContext,
    setPriorityContext,
    hasPendingInquiryArrivedContext,
    setHasPendingInquiryArrivedContext,
    crimeContext,
    setCrimeContext,
    lawContext,
    setLawContext,
    responsibleUserContext,
    setResponsibleUserContext,
    pageContext,
    setPageContext,
    pageLinkingContext,
    setPageLinkingContext,
    hasDiligenceResponseContext,
    setHasDiligenceResponseContext,
    returnedFromPmContext,
    setReturnedFromPmContext,
    hasActivatedTaskContext,
    setHasActivatedTaskContext,
    classInquiryContext,
    setClassInquiryContext,
    forwarderInstitutionContext,
    finalInstitutionContext,
    setForwarderInstitutionContext,
    setFinalInstitutionContext,
    isArchivalScienceContext,
    setIsArchivalScienceContext,
    hasPendenciesInAgreement,
    setHasPendenciesInAgreement,
    agreementStatus,
    setAgreementStatus,
    tasksDeadline,
    setTasksDeadline,
    victimIntimationDeadline,
    setVictimIntimationDeadline,
  } = useFilterInquiry();

  const { isPM } = useUserTypes();

  const [statusProcessing, setStatusProcessing] = useState(false);
  const [statusAwaitingVerification, setStatusAwaitingVerification] = useState(false);
  const [statusOnlyConcludedIn24h, setStatusOnlyConcludedIn24h] = useState(false);
  const [statusProcessingDiligence, setStatusProcessingDiligence] = useState(false);
  const [showProgress, setShowProgress] = useState(false);

  const useStyles = makeStyles(theme => ({
    divContainerInputs: {
      marginTop: theme.spacing(2.5),
      width: '100%',
      height: '100%',
      boxShadow: 'none',
      fontFamily: 'roboto-regular',
    },
    checkBox: {
      marginRight: 5,
      marginTop: 5,
      height: 15,
      width: 20,
      cursor: 'pointer',
    },
    labelBox: {
      marginTop: 5,
      paddingRight: 10,
      fontSize: 15,
      cursor: 'pointer',
    },
    inputInquiry: {
      height: 35,
      width: '100%',
      border: `1px solid #747474`,
      padding: 10,
      backgroundColor: 'transparent',
      marginRight: 10,
      marginTop: 5,
    },
    selectInput: {
      height: 35,
      width: '100%',
      border: `1px solid #747474`,
      paddingLeft: 10,
      backgroundColor: 'transparent',
      marginRight: 10,
      marginTop: 5,
    },
    teste: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    calendar: {
      backgroundColor: '#3bc3bf',
      color: '#ffffff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 8,
      marginTop: 5,
      borderRadius: '50%',
      padding: 3,
    },
    resumoBusca: {
      height: 25,
      fontSize: 12,
      paddingLeft: 5,
      margin: 5,
      '@media (max-width:600px)': {
        marginBottom: 20,
      },
    },
    divInputs: {
      padding: 5,
    },
    btnSearch: {
      marginTop: 4,
      backgroundColor: '#3bc3bf',
      border: `1px solid #ccc`,
      justifyContent: 'center',
      alignItems: 'center',
      color: '#ffffff',
      height: 37,
      width: '100%',
      borderRadius: 5,
      '&:hover': {
        backgroundColor: '#00597b',
      },
    },
    classeList: {
      overflow: 'auto',
      minHeight: '45vh',
      boxShadow: 'none',
    },
    labelStatusProcessing: {
      color: '#2c7692',
      padding: 5,
      fontSize: 13,
      cursor: 'pointer',
      fontWeight: statusProcessing ? 'bold' : 'normal',
    },
    labelStatusWaitingVerification: {
      color: '#2c7692',
      padding: 5,
      fontSize: 13,
      cursor: 'pointer',
      fontWeight: statusAwaitingVerification ? 'bold' : 'normal',
    },
    labelStatusConcludedIn24h: {
      color: '#2c7692',
      padding: 5,
      fontSize: 13,
      cursor: 'pointer',
      fontWeight: statusOnlyConcludedIn24h ? 'bold' : 'normal',
    },
    labelStatusProcessingDiligence: {
      color: '#2c7692',
      padding: 5,
      fontSize: 13,
      cursor: 'pointer',
      fontWeight: statusProcessingDiligence ? 'bold' : 'normal',
    },
    labelsimple: {
      color: '#2c7692',
    },
    divPagination: {
      height: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 12,
      marginTop: 15,
    },
    labelAnterior: {
      color: '#a7a9aa',
      cursor: 'pointer',
    },
    spanPaginacao: {
      color: '#2c7692',
    },
    spanProximo: {
      color: '#2c7692',
    },
    autoContainer: {
      marginTop: -220,
      '@media (max-width:600px)': {
        marginTop: -210,
        Height: 150,
        maxHeight: 175,
        minHeight: 175,
        maxWidth: '85%',
        minWidth: '87%',
        position: 'relative',
      },
      position: 'fixed',
      zIndex: 5,
      Height: 175,
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: 1,
      },
      backgroundColor: '#00597b',
      borderRadius: 10,
      padding: 5,
      border: '1px solid #999',
    },
    containerCrime: {
      position: 'relative',
      maxHeight: 175,
      minHeight: 175,
      maxWidth: 155,
      minWidth: 155,
    },
    optionCrime: {
      padding: 5,
      borderRadius: 2,
      borderBottom: '1px solid #999',
      '&:hover': {
        backgroundColor: '#3bc3bf',
      },
    },
    itemOptionCrime: {
      cursor: 'pointer',
      color: '#ffffff',
    },
    pagination: {
      '& > *': {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(1),
      },
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: 5,
    },
    circularProgress: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 5,
      marginBottom: -170,
      marginTop: 100,
    },
  }));

  const classes = useStyles();

  const {
    userId,
    type,
    groups,
    permissions: { consultasNaoDistribuidas, aiAssistant },
    prosecutorOffices: userProsecutorOffices,
    advisorProsecutor,
  } = useSelector(state => state.logedUser);
  const { selectedIndexGroup } = useSelector(state => state.groupMenu);
  const { userDistributionsOnly } = useSelector(state => state.userDistributionsOnly);
  const customerConfig = useSelector(state => state.customerConfig);
  const { allOriginNumbersAreNpu } = useCustomerConfig();
  const dispatch = useDispatch();

  const [stateSearch, setStateSearch] = useState(false);

  const [inputSearchCrime, setInputSearchCrime] = useState('');

  const [step, setStep] = useState('');
  const [inquiries, setInquiries] = useState({});
  const [prosecutors, setProsecutors] = useState([]);
  const [prosecutorOffices, setProsecutorOffices] = useState([]);
  const [prosecutorOfficesFilter, setProsecutorOfficesFilter] = useState([]);
  const [crimes, setCrimes] = useState([]);
  const [page, setPage] = useState(linking ? pageLinkingContext : pageContext);
  const size = 20;
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [isResult, setIsResult] = useState(false);
  const [sizeDataApi, setSizeDataApi] = useState(0);
  const [loadingCrimes, setLoadingCrimes] = useState(false);
  const [finalInstitutions, setFinalInstitutions] = useState([]);
  const [forwarderInstitutions, setForwarderInstitutions] = useState([]);
  const [excelUrl, setExcelUrl] = useState('');

  const [responsibles, setResponsibles] = useState(
    responsibleUserContext ? [responsibleUserContext] : [],
  );

  const [loadingResponsibles, setLoadingResponsibles] = useState(false);
  const [pageResponsable, setPageResponsable] = useState(1);
  const [lastPageResponsable, setLastPageResponsable] = useState(1);
  const [inputSearchResponsable, setInputSearchResponsable] = useState('');

  const [openModalClasse, setOpenModalClasse] = useState(false);

  const initialValues = {
    prosecutorOfficeId: prosecutorOfficeIdContext || 'TODAS_AS_PROMOTORIAS',
    prosecutor: prosecutorContext || 'TODAS_OS_PROSECUTOR',
    inquiryStep: inquiryStepContext || 'TODAS_AS_FASES',
    policeInquiryNumber: policeInquiryNumberContext || '',
    internalSystemNumber: internalSystemNumberContext || '',
    investigatedName: investigatedNameContext || '',
    investigatedCpf: investigatedCpfContext || '',
    startDate: startDateContext || '',
    endDate: endDateContext || '',
    crimeNome: crimeNomeContext || '',
    crimeId: crimeIdContext || '',
    law: lawContext || '',
    crime: crimeContext || null,
    excludePersecution: excludePersecutionContext || true,
    excludeNonPersecution: excludeNonPersecutionContext || true,
    inquiryStatus: inquiryStatusContext || statusInquiries,
    onlyConcludedIn24h: onlyConcludedIn24hContext || false,
    isArrested: isArrestedContext || '',
    awaitingInstitutionResponse: awaitingInstitutionResponseContext || '',
    isFlagrant: isFlagrantContext || '',
    priority: priorityContext || '',
    hasPendingInquiryArrived: hasPendingInquiryArrivedContext || '',
    responsibleUser: responsibleUserContext || null,
    hasDiligenceResponse: diligence || hasDiligenceResponseContext || '',
    returnedFromPm: returnedFromPmContext || '',
    hasActivatedTask: hasActivatedTaskContext || '',
    classInquiry: classInquiryContext || null,
    classDescription: classInquiryContext ? classInquiryContext.description : '',
    finalInstitution: finalInstitutionContext || 'TODAS_AS_FINAL_INSTITUTIONS',
    forwarderInstitution: forwarderInstitutionContext || 'TODAS_AS_FORWARDER_INSTITUTIONS',
    hasPendenciesInAgreement: agreementPendency || hasPendenciesInAgreement || '',
    agreementStatus: agreementStatus || '',
    tasksDeadline: tasksDeadline || '',
    victimIntimationDeadline: victimIntimationDeadline || '',
    isArchivalScience: isArchivalScienceContext || '',
  };

  const [mask, setMask] = useState(
    allOriginNumbersAreNpu
      ? npuGenericInquiry
      : policeInquiryNumberContext?.match(/\d/g)?.length === 16
      ? pcpeGenericInquiry
      : genericInquiry,
  );

  const [formState, setFormState] = useState({
    isValid: false,
    values: { ...initialValues },
    touched: {},
    errors: {},
  });

  const handleOpenModalFileTemplate = () => (window.location.href = excelUrl);

  const handlePoliceInquiryNumberChange = event => {
    event.persist();
    const inputValue = event.target.value;
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
    if (!allOriginNumbersAreNpu) {
      const newMask = inputValue.match(/\d/g)?.length === 16 ? pcpeGenericInquiry : genericInquiry;
      setMask(newMask);
    }
  };

  const verifyMask = event => {
    const inputValue = event.target.value;
    if (inputValue.match(/\d/g)?.length === 16 && !allOriginNumbersAreNpu) {
      setMask(genericInquiry);
    }
  };

  const verifyOnPaste = event => {
    setMask(event.target.value?.length === 16 ? pcpeGenericInquiry : genericInquiry);
  };

  const DivCheck = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs justifyContent="flex-start">
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formState.values.excludePersecution}
                  onChange={handleChange}
                  name="excludePersecution"
                  color="secondary"
                />
              }
              label="Persecução"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formState.values.excludeNonPersecution}
                  onChange={handleChange}
                  name="excludeNonPersecution"
                  color="secondary"
                />
              }
              label="Não Persecução"
            />
          </FormGroup>
        </Grid>
        <Grid justifyContent="flex-end">
          <Tooltip title="Exportar relatório de procedimentos" arrow placement="top">
            <IconButton onClick={handleOpenModalFileTemplate}>
              <SiMicrosoftexcel />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  const DivStatus = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs justifyContent="flex-start">
          <label className={classes.labelStatusProcessing} onClick={handleChangeProcessing}>
            EM PROCESSAMENTO
          </label>
          <label className={classes.labelsimple}>|</label>
          <label
            className={classes.labelStatusConcludedIn24h}
            onClick={handleChangeOnlyConcludedIn24h}
          >
            CONCLUÍDOS EM 24 HORAS
          </label>
          <label className={classes.labelsimple}>|</label>
          <label
            className={classes.labelStatusWaitingVerification}
            onClick={handleChangeWaitingVerification}
          >
            AGUARDANDO VERIFICAÇÃO
          </label>
          <label className={classes.labelsimple}>|</label>
          <label
            className={classes.labelStatusProcessingDiligence}
            onClick={handleChangeProcessingDiligence}
          >
            AGUARDANDO REQUISITÓRIO
          </label>
        </Grid>
        <Grid justifyContent="flex-end">
          <Tooltip title="Exportar relatório de procedimentos" arrow placement="top">
            <IconButton onClick={handleOpenModalFileTemplate}>
              <SiMicrosoftexcel />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  const handleAddClasse = (event, classe) => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        classInquiry: classe,
        classDescription: classe.description,
      },
    }));
  };

  const handleClearClasses = () => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        classInquiry: null,
        classDescription: '',
      },
    }));
  };

  const handleOpenModalClasse = () => {
    setOpenModalClasse(true);
  };

  const handleCloseModalClasse = () => {
    setOpenModalClasse(false);
  };

  const handleOpenChatAiSearchInquiries = () => {
    dispatch(setChatAiFeature(CHAT_AI_FEATURES.SEARCH_INQUIRIES));
    dispatch(
      setAiMessages({
        sender: CHAT_AI_SENDERS.AI,
        text:
          'Claro, ficarei feliz em lhe ajudar a encontrar um inquérito específico, ou um grupo de inquéritos, existente no Consensus. Quais os parâmetros do(s) inquérito(s) que você deseja encontrar? Cite dados como classe, cpf do investigado, número interno e etc...',
        type: CHAT_AI_TEXT_TYPES.TEXT,
      }),
    );
    dispatch(setChatAiSituation(chatAiPossibleStates.open));
  };

  const shouldDisplayReturnedFromPmField = inquiryStep => {
    return screeningNotPursuit || inquiryStep === STEPS_INQUIRY.TRIAGEM;
  };

  const handleChangeProcessing = () => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        inquiryStatus: !statusProcessing ? 'processing' : '',
        onlyConcludedIn24h: false,
      },
    }));
    setStatusProcessing(!statusProcessing);
    setStatusAwaitingVerification(false);
    setStatusOnlyConcludedIn24h(false);
    setStatusProcessingDiligence(false);
  };

  const handleChangeOnlyConcludedIn24h = () => {
    setStatusProcessing(false);
    setStatusAwaitingVerification(false);
    setStatusOnlyConcludedIn24h(!statusOnlyConcludedIn24h);
    setStatusProcessingDiligence(false);

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        onlyConcludedIn24h: !statusOnlyConcludedIn24h ? true : false,
        inquiryStatus: !statusOnlyConcludedIn24h ? '' : formState.values.inquiryStatus || '',
      },
    }));
  };

  const handleChangeWaitingVerification = () => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        inquiryStatus: !statusAwaitingVerification ? 'awaiting-verification' : '',
        onlyConcludedIn24h: false,
      },
    }));
    setStatusProcessing(false);
    setStatusAwaitingVerification(!statusAwaitingVerification);
    setStatusOnlyConcludedIn24h(false);
    setStatusProcessingDiligence(false);
  };

  const handleChangeProcessingDiligence = () => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        inquiryStatus: !statusProcessingDiligence ? 'processing-diligence' : '',
        onlyConcludedIn24h: false,
      },
    }));
    setStatusProcessing(false);
    setStatusAwaitingVerification(false);
    setStatusOnlyConcludedIn24h(false);
    setStatusProcessingDiligence(!statusProcessingDiligence);
  };

  const handleSubmitForm = event => {
    event.preventDefault();
    mapListViewCards();
  };

  const mapListViewCards = () => {
    setStateSearch(false);

    //setStateVerification(false);

    const fetchData = {
      page,
      size,
      inquiryStep: '',
      startDate: formState.values.startDate,
      endDate: formState.values.endDate,
      prosecutor: formState.values.prosecutor,
      excludePersecution: formState.values.excludePersecution,
      excludeNonPersecution: formState.values.excludeNonPersecution,
      inquiryStatus: formState.values.inquiryStatus,
      onlyConcludedIn24h: formState.values.onlyConcludedIn24h,
    };

    if (idLinkingInquiry) {
      fetch({
        ...fetchData,
        inquiryStep: 'ANALISE',
        inquiryStatus: statusInquiries,
        onlyConcludedIn24h: false,
      });
      setStep('VINCULADOS');
    }

    if (details) {
      //Requisição de todas as fases do inquérito
      fetch({
        ...fetchData,
        inquiryStep: formState.values.inquiryStep,
      });
      setStep('details');
    }

    if (screeningNotPursuit) {
      fetch({
        ...fetchData,
        inquiryStep: STEPS_INQUIRY.TRIAGEM,
      });
      setStep(STEPS_INQUIRY.TRIAGEM);
    }

    if (searchDistribution) {
      fetch({
        ...fetchData,
        inquiryStep: STEPS_INQUIRY.PESQUISA,
      });
      setStep(STEPS_INQUIRY.PESQUISA);
    }

    if (migration) {
      fetch({
        ...fetchData,
        inquiryStep: STEPS_INQUIRY.MIGRACAO,
      });
      setStep(STEPS_INQUIRY.MIGRACAO);
    }

    if (analyze) {
      let customStep = formState.values.inquiryStep;

      if (customStep !== 'TODAS_AS_FASES' && customStep !== STEPS_INQUIRY.ENCAMINHAR_DILIGENCIAS) {
        customStep = STEPS_INQUIRY.ANALISE;
      }

      if (customStep === 'TODAS_AS_FASES') {
        customStep = STEPS_INQUIRY.ANALISE;
      }

      fetch({
        ...fetchData,
        inquiryStep: customStep,
      });
      setStep(STEPS_INQUIRY.ANALISE);
    }

    if (processing) {
      fetch({
        ...fetchData,
        inquiryStep: STEPS_INQUIRY.TRAMITACAO_CONTROLE,
      });
      setStep(STEPS_INQUIRY.TRAMITACAO_CONTROLE);
    }

    if (intimation) {
      fetch({
        ...fetchData,
        inquiryStep: STEPS_INQUIRY.INTIMACAO,
      });
      setStep(STEPS_INQUIRY.INTIMACAO);
    }

    if (agreement) {
      fetch({
        ...fetchData,
        inquiryStep: STEPS_INQUIRY.CUMPRIMENTO_ACORDO,
      });
      setStep(STEPS_INQUIRY.CUMPRIMENTO_ACORDO);
    }

    if (hearing) {
      fetch({
        ...fetchData,
        inquiryStep: STEPS_INQUIRY.AUDIENCIA,
      });
      setStep(STEPS_INQUIRY.AUDIENCIA);
    }

    if (forwardingTerm) {
      fetch({
        ...fetchData,
        inquiryStep: STEPS_INQUIRY.TERMO_DE_ENCAMINHAMENTO,
      });
      setStep(STEPS_INQUIRY.TERMO_DE_ENCAMINHAMENTO);
    }
  };

  const fetch = ({
    page,
    size,
    inquiryStep = '',
    startDate,
    endDate,
    prosecutor = '',
    excludePersecution = true,
    excludeNonPersecution = true,
    inquiryStatus = '',
    onlyConcludedIn24h = false,
  }) => {
    if (inquiryStep === 'TODAS_AS_FASES') {
      inquiryStep = '';
    }

    excludePersecution = !excludePersecution;
    excludeNonPersecution = !excludeNonPersecution;

    let params = {
      page,
      size,
      excludePersecution,
      excludeNonPersecution,
      onlyConcludedIn24h: Boolean(onlyConcludedIn24h),
      prosecutorOfficeGroupIds: groups[selectedIndexGroup]?.id,
    };

    if (formState.values.agreementStatus !== '') {
      if (formState.values.agreementStatus === 'notApproved') {
        params.agreementWasApproved = false;
      } else {
        params.agreementStatus = formState.values.agreementStatus;
      }
    }

    if (startDate) {
      params.startDate = formatDateToISOString(
        addHoursMinutesSecondsToStartDatesFilter(startDate),
        true,
      );
    }

    if (endDate) {
      params.endDate = formatDateToISOString(
        addHoursMinutesSecondsToFinalDatesFilter(endDate),
        true,
      );
    }

    if (inquiryStatus !== '' && inquiryStatus !== 'onlyConcludedIn24h') {
      params.inquiryStatus = inquiryStatus;
    }

    if (formState.values.crime) {
      params.crimeId = formState.values.crime.id;
    }

    if (inquiryStep !== '') {
      params.inquiryStep = inquiryStep;
    }

    if (formState.values.internalSystemNumber !== '') {
      params.internalSystemNumber = formState.values.internalSystemNumber;
    }

    if (formState.values.investigatedName !== '') {
      params.investigatedName = formState.values.investigatedName;
    }

    if (formState.values.policeInquiryNumber !== '') {
      params.policeInquiryNumber = clearInquiryNumber(formState.values.policeInquiryNumber);
    }

    if (formState.values.investigatedCpf !== '') {
      params.investigatedCpf = formState.values.investigatedCpf;
    }

    /*consultasNaoDistribuidas = permissão de coordenador. Caso seja true, não enviar o parâmetro => prosecutorId*/
    if (prosecutor === 'TODAS_OS_PROSECUTOR') {
      if ((type === 'Prosecutor' || advisorProsecutor) && !linking) {
        if (!consultasNaoDistribuidas.read || !consultasNaoDistribuidas.write) {
          if (userDistributionsOnly) {
            params.prosecutorId = advisorProsecutor?.id || userId;
          }
          const prosecutorOfficesPermitted =
            advisorProsecutor?.prosecutorOffices || userProsecutorOffices;
          params.prosecutorOfficeIds =
            prosecutorOfficesPermitted?.map(prosecutorOffice => prosecutorOffice.id).join(',') ||
            undefined;
        } else {
          if (userDistributionsOnly) {
            params.prosecutorId = advisorProsecutor?.id || userId;
          }
        }
      }
    } else {
      params.prosecutorId = prosecutor;
    }

    if (
      formState.values.prosecutorOfficeId &&
      formState.values.prosecutorOfficeId !== 'TODAS_AS_PROMOTORIAS'
    ) {
      params.prosecutorOfficeId = formState.values.prosecutorOfficeId;
    }

    if (
      formState.values.finalInstitution &&
      formState.values.finalInstitution !== 'TODAS_AS_FINAL_INSTITUTIONS'
    ) {
      params.finalInstitutionId = formState.values.finalInstitution;
    }

    if (formState.values.hasPendenciesInAgreement !== '') {
      params.pending = formState.values.hasPendenciesInAgreement;
    }

    if (
      formState.values.forwarderInstitution &&
      formState.values.forwarderInstitution !== 'TODAS_AS_FORWARDER_INSTITUTIONS'
    ) {
      params.forwarderInstitutionId = formState.values.forwarderInstitution;
    }

    if (formState.values.isArrested !== '') {
      params.isArrested = formState.values.isArrested;
    }

    if (formState.values.awaitingInstitutionResponse !== '') {
      params.awaitingInstitutionResponse = formState.values.awaitingInstitutionResponse;
    }

    if (formState.values.hasDiligenceResponse !== '') {
      params.hasDiligenceResponse = formState.values.hasDiligenceResponse;
    }

    if (formState.values.returnedFromPm !== '') {
      params.returnedFromPm = formState.values.returnedFromPm;
    }

    if (formState.values.hasActivatedTask !== '') {
      params.hasActivatedTask = formState.values.hasActivatedTask;
    }

    if (formState.values.tasksDeadline !== '') {
      params.tasksDeadline = formState.values.tasksDeadline;
    }

    if (formState.values.victimIntimationDeadline !== '') {
      params.victimIntimationDeadline = formState.values.victimIntimationDeadline;
    }

    if (formState.values.isFlagrant !== '') {
      params.isFlagrant = formState.values.isFlagrant;
    }

    if (formState.values.isArchivalScience !== '') {
      params.isArchivalScience = formState.values.isArchivalScience;
    }

    if (formState.values.priority !== '') {
      if (formState.values.priority === 'Elderly') {
        params.isVictimElderly = true;
      }
      if (formState.values.priority === 'ChildTeen') {
        params.isVictimChildTeen = true;
      }
      if (formState.values.priority === 'ViolenceAgainstWomen' && formState.values.law) {
        params.lawId = formState.values.law.id;
      }
    }

    if (formState.values.hasPendingInquiryArrived !== '') {
      params.hasPendingInquiryArrived = formState.values.hasPendingInquiryArrived;
    }

    if (formState.values.responsibleUser) {
      params.responsibleUserId = formState.values.responsibleUser.id;
    }

    if (onlyConcludedIn24h || inquiryStatus === 'onlyConcludedIn24h') {
      params.fromStepIn24h = inquiryStep;
      params.inquiryStep = null;
      params.inquiryStatus = null;
    }

    delete params.onlyConcludedIn24h;

    if (inquiryStep === 'AUDIENCIA') {
      params.startHearingDate = startDate;
      params.endHearingDate = endDate;
      params.startDate = null;
      params.endDate = null;
    }

    if (linking) {
      params.inquiryId = idLinkingInquiry;
      params.inquiryStep = null;
      params.policeInquiryNumber = null;
      params.excludePersecution = null;
      params.excludeNonPersecution = null;
      params.inquiryStatus = null;
      params.onlyConcludedIn24h = null;
      params.prosecutorOfficeGroupIds = null;
    }

    if (formState.values.classInquiry) {
      if (formState.values.classInquiry.classes) {
        params.classe = formState.values.classInquiry.classes.map(c => c.code).join(',');
      } else {
        params.classe = formState.values.classInquiry.code;
      }
    }

    setShowProgress(true);

    if (isPM) {
      pmService
        .getInquiriesInvestigatedPmService(params)
        .then(response => {
          executeResponse(
            response,
            inquiryStep,
            startDate,
            endDate,
            excludePersecution,
            excludeNonPersecution,
            inquiryStatus,
            onlyConcludedIn24h,
            page,
          );
        })
        .finally(() => {
          setShowProgress(false);
        });
    } else {
      inquiryService
        .getInquiriesInvestigatedService(params)
        .then(response => {
          executeResponse(
            response,
            inquiryStep,
            startDate,
            endDate,
            excludePersecution,
            excludeNonPersecution,
            inquiryStatus,
            onlyConcludedIn24h,
            page,
          );
        })
        .finally(() => {
          setShowProgress(false);
        });
    }
  };

  const executeResponse = (
    response,
    inquiryStep,
    startDate,
    endDate,
    excludePersecution,
    excludeNonPersecution,
    inquiryStatus,
    onlyConcludedIn24h,
    page,
  ) => {
    setInquiries(response.data.data);
    setTotalPages(response.data.lastPage);
    setTotal(response.data.total);
    setExcelUrl(response.data.excelUrl);
    setStateSearch(true);
    setIsResult(true);
    setSizeDataApi(response.data.data.length);
    setProsecutorContext(formState.values.prosecutor);
    setProsecutorOfficeIdContext(formState.values.prosecutorOfficeId);
    setPoliceInquiryNumberContext(clearInquiryNumber(formState.values.policeInquiryNumber));
    setInternalSystemNumberContext(formState.values.internalSystemNumber);
    setInvestigatedNameContext(formState.values.investigatedName);
    setInvestigatedCpfContext(formState.values.investigatedCpf);
    setStartDateContext(startDate);
    setEndDateContext(endDate);
    setExcludePersecutionContext(excludePersecution);
    setExcludeNonPersecutionContext(excludeNonPersecution);
    setInquiryStatusContext(inquiryStatus);
    setOnlyConcludedIn24hContext(onlyConcludedIn24h);
    setIsArrestedContext(formState.values.isArrested);
    setAwaitingInstitutionResponseContext(formState.values.awaitingInstitutionResponse);
    setIsFlagrantContext(formState.values.isFlagrant);
    setPriorityContext(formState.values.priority);
    setHasPendingInquiryArrivedContext(formState.values.hasPendingInquiryArrived);
    setCrimeContext(formState.values.crime);
    setResponsibleUserContext(formState.values.responsibleUser);
    setPage(page);
    setHasDiligenceResponseContext(formState.values.hasDiligenceResponse);
    setReturnedFromPmContext(formState.values.returnedFromPm);
    setHasActivatedTaskContext(formState.values.hasActivatedTask);
    setClassInquiryContext(formState.values.classInquiry);
    setFinalInstitutionContext(formState.values.finalInstitution);
    setForwarderInstitutionContext(formState.values.forwarderInstitution);
    setHasPendenciesInAgreement(formState.values.hasPendenciesInAgreement);
    setAgreementStatus(formState.values.agreementStatus);
    setTasksDeadline(formState.values.tasksDeadline);
    setVictimIntimationDeadline(formState.values.victimIntimationDeadline);
    setIsArchivalScienceContext(formState.values.isArchivalScience);

    if (linking) {
      setPageLinkingContext(page);
    } else {
      setPageContext(page);
      setInquiryStepContext(inquiryStep);
    }
  };

  const getCrimes = (search = '') => {
    setLoadingCrimes(true);

    let params = {
      search,
      size: 20,
    };

    if (isPM) {
      return pmService
        .getCrimesPmService(params)
        .then(response => {
          executeResponseCrimes(response);
        })
        .finally(() => setLoadingCrimes(false));
    } else {
      return crimeService
        .getCrimesService(params)
        .then(response => {
          executeResponseCrimes(response);
        })
        .finally(() => setLoadingCrimes(false));
    }
  };

  const executeResponseCrimes = response => {
    if (response.data.data.length > 0) {
      setCrimes(
        response.data.data.sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0;
        }),
      );
    } else {
      setCrimes([]);
    }
  };

  const populateProsecutors = items => {
    setProsecutors(
      items.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      }),
    );
  };

  const populateFinalInstitutions = items => {
    setFinalInstitutions(
      items.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      }),
    );
  };

  const populateForwarderInstitutions = items => {
    setForwarderInstitutions(
      items.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      }),
    );
  };

  const getInstitutions = async page => {
    let params = {
      page,
      forward: false,
      size: 100,
    };

    await associationService.getAssociationsService(params).then(response => {
      if (response.data.data.length > 0) {
        populateFinalInstitutions(response.data.data);
      }
    });

    params.forward = true;
    associationService.getAssociationsService(params).then(response => {
      if (response.data.data.length > 0) {
        populateForwarderInstitutions(response.data.data);
      }
    });
  };

  const getProsecutor = (page, prosecutorOfficeId = '', type2 = 'Prosecutor') => {
    let params = {
      page,
      type2,
      prosecutorOfficeGroupIds: prosecutorOfficeId,
      size: 100,
    };

    userService.getProsecutorsService(params).then(response => {
      if (response.data.data.length > 0) {
        const logedUser = response.data.data.find(user => user.id === userId);

        if (prosecutorOfficeIdContext) {
          setFormState(state => ({
            ...state,
            values: {
              ...state.values,
              prosecutorOfficeId: prosecutorOfficeIdContext,
            },
          }));
        }

        if (type === 'Prosecutor' || advisorProsecutor) {
          if (!consultasNaoDistribuidas.read) {
            const prosecutor = advisorProsecutor || logedUser;
            setProsecutorOffices(prosecutor?.prosecutorOffices || []);
            setProsecutors(prosecutor ? [prosecutor] : []);
            setProsecutorOfficesFilter(prosecutor?.prosecutorOffices || []);
          } else {
            populateProsecutors(response.data.data);
            setProsecutorOffices(response.data.data.map(item => item.prosecutorOffices).flat(2));
            setProsecutorOfficesFilter(_prosecutorOfficesFilter => {
              if (prosecutorOfficeIdContext) {
                return response.data.data
                  .filter(prosec => prosec.id === formState.values.prosecutor)
                  .map(item => item.prosecutorOffices)
                  .flat(2);
              }

              return response.data.data.map(item => item.prosecutorOffices).flat(2);
            });
          }
        } else {
          populateProsecutors(response.data.data);

          setProsecutorOffices(response.data.data.map(item => item.prosecutorOffices).flat(2));
          setProsecutorOfficesFilter(_prosecutorOfficesFilter => {
            if (prosecutorOfficeIdContext && formState.values.prosecutor !== 'TODAS_OS_PROSECUTOR') {
              return response.data.data
              .filter(prosec => prosec.id === formState.values.prosecutor)
              .map(item => item.prosecutorOffices)
              .flat(2);
            }
            return response.data.data.map(item => item.prosecutorOffices).flat(2);
          });
        }
      } else {
        setProsecutors([]);
      }
    });
  };

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handlePriority = event => {
    event.persist();

    if (
      event.target.value === 'ViolenceAgainstWomen' &&
      lawContext?.name !== 'Lei Maria da Penha'
    ) {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          priority: '',
        },
      }));
      const params = {
        search: 'Lei Maria da Penha',
      };
      lawService.getLawService(params).then(response => {
        const law = response.data?.data;
        if (law.length) {
          setLawContext(law[0]);
          setFormState(formState => ({
            ...formState,
            values: {
              ...formState.values,
              law: law[0],
              priority: 'ViolenceAgainstWomen',
            },
          }));
        }
      });
    }
  };

  const handlePageChange = (event, value) => {
    const fetchData = {
      page: value,
      size,
      inquiryStep: '',
      startDate: formState.values.startDate,
      endDate: formState.values.endDate,
      prosecutor: formState.values.prosecutor,
      excludePersecution: formState.values.excludePersecution,
      excludeNonPersecution: formState.values.excludeNonPersecution,
      inquiryStatus: formState.values.inquiryStatus,
      onlyConcludedIn24h: formState.values.onlyConcludedIn24h,
    };
    if (page !== value) {
      setStateSearch(false);
      if (idLinkingInquiry) {
        fetch({
          ...fetchData,
          inquiryStep: STEPS_INQUIRY.ANALISE,
          inquiryStatus: statusInquiries,
          onlyConcludedIn24h: false,
        });
        setStep('VINCULADOS');
      }

      if (details) {
        //Requisição de todas as fases do inquérito
        fetch({
          ...fetchData,
          inquiryStep: formState.values.inquiryStep,
        });
        setStep('details');
      }

      if (screeningNotPursuit) {
        fetch({
          ...fetchData,
          inquiryStep: STEPS_INQUIRY.TRIAGEM,
        });
        setStep(STEPS_INQUIRY.TRIAGEM);
      }

      if (searchDistribution) {
        fetch({
          ...fetchData,
          inquiryStep: STEPS_INQUIRY.PESQUISA,
        });
        setStep(STEPS_INQUIRY.PESQUISA);
      }

      if (migration) {
        fetch({
          ...fetchData,
          inquiryStep: STEPS_INQUIRY.MIGRACAO,
        });
        setStep(STEPS_INQUIRY.MIGRACAO);
      }

      if (analyze) {
        let customStep = formState.values.inquiryStep;

        if (
          customStep !== 'TODAS_AS_FASES' &&
          customStep !== STEPS_INQUIRY.ENCAMINHAR_DILIGENCIAS
        ) {
          customStep = STEPS_INQUIRY.ANALISE;
        }

        if (customStep === 'TODAS_AS_FASES') {
          customStep = STEPS_INQUIRY.ANALISE;
        }

        fetch({
          ...fetchData,
          inquiryStep: customStep,
        });
        setStep(STEPS_INQUIRY.ANALISE);
      }

      if (intimation) {
        fetch({
          ...fetchData,
          inquiryStep: STEPS_INQUIRY.INTIMACAO,
        });
        setStep(STEPS_INQUIRY.INTIMACAO);
      }

      if (hearing) {
        fetch({
          ...fetchData,
          inquiryStep: STEPS_INQUIRY.AUDIENCIA,
        });
        setStep(STEPS_INQUIRY.AUDIENCIA);
      }

      if (forwardingTerm) {
        fetch({
          ...fetchData,
          inquiryStep: STEPS_INQUIRY.TERMO_DE_ENCAMINHAMENTO,
        });
        setStep(STEPS_INQUIRY.TERMO_DE_ENCAMINHAMENTO);
      }

      if (processing) {
        fetch({
          ...fetchData,
          inquiryStep: STEPS_INQUIRY.TRAMITACAO_CONTROLE,
        });
        setStep(STEPS_INQUIRY.TRAMITACAO_CONTROLE);
      }
    }
  };

  const debouncedGetCrimes = useCallback(
    debounce(newValue => getCrimes(newValue), 500),
    [],
  );

  const updateValueCrimes = newValue => {
    debouncedGetCrimes(newValue);
    setInputSearchCrime(newValue);
  };

  const handleScroll = event => {
    const listboxNode = event.currentTarget;

    const position = listboxNode.scrollTop + listboxNode.clientHeight;

    if (listboxNode.scrollHeight - position <= 1 && pageResponsable < lastPageResponsable) {
      loadMoreResults();
    }
  };

  const mergeResponsibles = (responsibles = [], moreResponsibles = []) => {
    return _.orderBy(_.uniqBy([...responsibles, ...moreResponsibles], 'id'), [
      item =>
        _.get(item, 'name')
          .toLocaleLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, ''),
    ]);
  };

  const populateArrayResponsibles = data => {
    if (formState.values.responsibleUser) {
      setResponsibles(
        mergeResponsibles([
          ...responsibles,
          ...data.filter(item => item.id !== formState.values.responsibleUser.id),
        ]),
      );
    } else {
      setResponsibles(mergeResponsibles([...responsibles, ...data]));
    }
  };

  const getResponsiblesForInquirys = page => {
    if (loadingResponsibles) {
      return;
    }

    setLoadingResponsibles(true);

    let params = {
      page,
      size,
      search: inputSearchResponsable,
    };

    userService
      .getResponsiblesForInquiryService(groups[selectedIndexGroup].id, params)
      .then(response => {
        populateArrayResponsibles(response.data.data);
        setLastPageResponsable(response.data.lastPage);
      })
      .finally(() => setLoadingResponsibles(false));
  };

  const loadMoreResults = () => {
    const nextPage = pageResponsable + 1;

    setPageResponsable(nextPage);

    let params = {
      page: nextPage,
      size,
      search: inputSearchResponsable,
    };

    setLoadingResponsibles(true);

    userService
      .getResponsiblesForInquiryService(groups[selectedIndexGroup].id, params)
      .then(response => populateArrayResponsibles(response.data.data))
      .finally(() => setLoadingResponsibles(false));
  };

  useLayoutEffect(() => {
    const fetchData = {
      page,
      size,
      inquiryStep: '',
      startDate: formState.values.startDate,
      endDate: formState.values.endDate,
      prosecutor: formState.values.prosecutor,
      excludePersecution: formState.values.excludePersecution,
      excludeNonPersecution: formState.values.excludeNonPersecution,
      inquiryStatus: formState.values.inquiryStatus,
      onlyConcludedIn24h: false,
    };
    if (idLinkingInquiry) {
      setStateSearch(false);
      fetch({
        ...fetchData,
        inquiryStep: STEPS_INQUIRY.TRIAGEM,
        inquiryStatus: statusInquiries,
        onlyConcludedIn24h: false,
      });
      setStep('VINCULADOS');
    }

    if (statusInquiries && statusInquiries !== '') {
      if (statusInquiries === 'onlyConcludedIn24h') {
        setStatusOnlyConcludedIn24h(true);
      } else if (statusInquiries === 'processing') {
        setStatusProcessing(true);
      } else if (statusInquiries === 'awaiting-verification') {
        setStatusAwaitingVerification(true);
      } else if (statusInquiries === 'processing-diligence') {
        setStatusProcessingDiligence(true);
      }

      if (screeningNotPursuit) {
        if (statusInquiries !== 'onlyConcludedIn24h') {
          setStateSearch(false);
          fetch({
            ...fetchData,
            inquiryStep: STEPS_INQUIRY.TRIAGEM,
            inquiryStatus: statusInquiries,
            onlyConcludedIn24h: false,
          });
          setStep(STEPS_INQUIRY.TRIAGEM);
        } else {
          setStateSearch(false);
          fetch({
            ...fetchData,
            inquiryStep: STEPS_INQUIRY.TRIAGEM,
            onlyConcludedIn24h: true,
          });
          setStep(STEPS_INQUIRY.TRIAGEM);
        }
      }

      if (searchDistribution) {
        if (statusInquiries !== 'onlyConcludedIn24h') {
          setStateSearch(false);
          fetch({
            ...fetchData,
            inquiryStep: STEPS_INQUIRY.PESQUISA,
            inquiryStatus: statusInquiries,
            onlyConcludedIn24h: false,
          });
          setStep(STEPS_INQUIRY.PESQUISA);
        } else {
          setStateSearch(false);
          fetch({
            ...fetchData,
            inquiryStep: STEPS_INQUIRY.PESQUISA,
            onlyConcludedIn24h: true,
          });
          setStep(STEPS_INQUIRY.PESQUISA);
        }
      }

      if (migration) {
        if (statusInquiries !== 'onlyConcludedIn24h') {
          setStateSearch(false);
          fetch({
            ...fetchData,
            inquiryStep: STEPS_INQUIRY.MIGRACAO,
            inquiryStatus: statusInquiries,
            onlyConcludedIn24h: false,
          });
          setStep(STEPS_INQUIRY.MIGRACAO);
        } else {
          setStateSearch(false);
          fetch({
            ...fetchData,
            inquiryStep: STEPS_INQUIRY.MIGRACAO,
            onlyConcludedIn24h: true,
          });
          setStep(STEPS_INQUIRY.MIGRACAO);
        }
      }

      if (analyze) {
        if (
          statusInquiries !== 'onlyConcludedIn24h' &&
          statusInquiries !== STEPS_INQUIRY.ENCAMINHAR_DILIGENCIAS
        ) {
          setStateSearch(false);
          fetch({
            ...fetchData,
            inquiryStep: STEPS_INQUIRY.ANALISE,
            inquiryStatus: statusInquiries,
            onlyConcludedIn24h: false,
          });
          setStep(STEPS_INQUIRY.ANALISE);
        } else if (statusInquiries === STEPS_INQUIRY.ENCAMINHAR_DILIGENCIAS) {
          setStateSearch(false);
          fetch({
            ...fetchData,
            inquiryStep: STEPS_INQUIRY.ENCAMINHAR_DILIGENCIAS,
            inquiryStatus: '',
            onlyConcludedIn24h: false,
          });
          setStep(STEPS_INQUIRY.ANALISE);
        } else {
          setStateSearch(false);
          fetch({
            ...fetchData,
            inquiryStep: STEPS_INQUIRY.ANALISE,
            onlyConcludedIn24h: true,
          });
          setStep(STEPS_INQUIRY.ANALISE);
        }
      }

      if (processing) {
        if (statusInquiries !== 'onlyConcludedIn24h') {
          setStateSearch(false);
          fetch({
            ...fetchData,
            inquiryStep: STEPS_INQUIRY.TRAMITACAO_CONTROLE,
            inquiryStatus: statusInquiries,
            onlyConcludedIn24h: false,
          });
          setStep(STEPS_INQUIRY.TRAMITACAO_CONTROLE);
        } else {
          setStateSearch(false);
          fetch({
            ...fetchData,
            inquiryStep: STEPS_INQUIRY.TRAMITACAO_CONTROLE,
            onlyConcludedIn24h: true,
          });
          setStep(STEPS_INQUIRY.TRAMITACAO_CONTROLE);
        }
      }

      if (intimation) {
        if (statusInquiries !== 'onlyConcludedIn24h') {
          setStateSearch(false);
          fetch({
            ...fetchData,
            inquiryStep: STEPS_INQUIRY.INTIMACAO,
            inquiryStatus: statusInquiries,
            onlyConcludedIn24h: false,
          });
          setStep(STEPS_INQUIRY.INTIMACAO);
        } else {
          setStateSearch(false);
          fetch({
            ...fetchData,
            inquiryStep: STEPS_INQUIRY.INTIMACAO,
            onlyConcludedIn24h: true,
          });
          setStep(STEPS_INQUIRY.INTIMACAO);
        }
      }

      if (hearing) {
        if (statusInquiries !== 'onlyConcludedIn24h') {
          setStateSearch(false);
          fetch({
            ...fetchData,
            inquiryStep: STEPS_INQUIRY.AUDIENCIA,
            inquiryStatus: statusInquiries,
            onlyConcludedIn24h: false,
          });
          setStep(STEPS_INQUIRY.AUDIENCIA);
        } else {
          setStateSearch(false);
          fetch({
            ...fetchData,
            inquiryStep: STEPS_INQUIRY.AUDIENCIA,
            onlyConcludedIn24h: true,
          });
          setStep(STEPS_INQUIRY.AUDIENCIA);
        }
      }

      if (forwardingTerm) {
        if (statusInquiries !== 'onlyConcludedIn24h') {
          setStateSearch(false);
          fetch({
            ...fetchData,
            inquiryStep: STEPS_INQUIRY.TERMO_DE_ENCAMINHAMENTO,
            inquiryStatus: statusInquiries,
            onlyConcludedIn24h: false,
          });
          setStep(STEPS_INQUIRY.TERMO_DE_ENCAMINHAMENTO);
        } else {
          setStateSearch(false);
          fetch({
            ...fetchData,
            inquiryStep: STEPS_INQUIRY.TERMO_DE_ENCAMINHAMENTO,
            onlyConcludedIn24h: true,
          });
          setStep(STEPS_INQUIRY.TERMO_DE_ENCAMINHAMENTO);
        }
      }

      if (agreement) {
        if (statusInquiries !== 'onlyConcludedIn24h') {
          setStateSearch(false);
          fetch({
            ...fetchData,
            inquiryStep: STEPS_INQUIRY.CUMPRIMENTO_ACORDO,
            inquiryStatus: statusInquiries,
            onlyConcludedIn24h: false,
          });
          setStep(STEPS_INQUIRY.CUMPRIMENTO_ACORDO);
        } else {
          setStateSearch(false);
          fetch({
            ...fetchData,
            inquiryStep: STEPS_INQUIRY.CUMPRIMENTO_ACORDO,
            onlyConcludedIn24h: true,
          });
          setStep(STEPS_INQUIRY.CUMPRIMENTO_ACORDO);
        }
      }
    } else {
      if (!linking) {
        mapListViewCards();
      }
    }
  }, []);

  useEffect(() => {
    if (!isPM) {
      getResponsiblesForInquirys(1);
    }
  }, []);

  useEffect(() => {
    if (selectedIndexGroup !== null && groups !== null) {
      getProsecutor(1, groups[selectedIndexGroup].id);
      getInstitutions(1);
    }
  }, [selectedIndexGroup]);

  useEffect(() => {
    const { prosecutor } = formState.values;
    if (prosecutor === 'TODAS_OS_PROSECUTOR') {
      setProsecutorOfficesFilter(prosecutorOffices);
      // setFormState(formState => ({
      //   ...formState,
      //   values: {
      //     ...formState.values,
      //     prosecutorOfficeId: 'TODAS_AS_PROMOTORIAS',
      //   },
      // }));
    } else {
      setProsecutorOfficesFilter(_prosecutorOfficesFilter => {
        const newProsecutors = prosecutors
          .filter(prosec => prosec.id === prosecutor)
          .map(item => item.prosecutorOffices)
          .flat(2);

        if (newProsecutors.indexOf(item => item.id === formState.values.prosecutorOfficeId) < 0) {
          setFormState(formState => ({
            ...formState,
            values: {
              ...formState.values,
              prosecutorOfficeId: '',
            },
          }));
        }

        return newProsecutors;
      });
    }
  }, [formState.values.prosecutor]);

  useEffect(() => {
    if (!isPM) {
      getResponsiblesForInquirys(1);
    }
  }, [inputSearchResponsable]);

  return (
    <Card className={classes.divContainerInputs}>
      <form id="filter-inquiry-form" onSubmit={handleSubmitForm}>
        <Grid container spacing={1}>
          {details ? (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <DivCheck />
            </Grid>
          ) : (
            <></>
          )}
          {intimation ||
          searchDistribution ||
          screeningNotPursuit ||
          analyze ||
          processing ||
          hearing ||
          agreement ||
          forwardingTerm ||
          migration ? (
            <DivStatus />
          ) : (
            <></>
          )}
          <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
            <select
              className={classes.selectInput}
              onChange={handleChange}
              value={formState.values.prosecutor}
              name="prosecutor"
            >
              <option value="TODAS_OS_PROSECUTOR">Promotor</option>
              {prosecutors.length > 0
                ? prosecutors.map(prosecutor => (
                    <option key={prosecutor.id} value={prosecutor.id}>
                      {prosecutor.name}
                    </option>
                  ))
                : ''}
            </select>
          </Grid>

          <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
            <select
              className={classes.selectInput}
              onChange={handleChange}
              value={formState.values.prosecutorOfficeId}
              name="prosecutorOfficeId"
            >
              <option value="TODAS_AS_PROMOTORIAS">Promotoria</option>
              {prosecutorOfficesFilter.length > 0
                ? prosecutorOfficesFilter.map((prosecutorOffice, index) => (
                    <option key={index} value={prosecutorOffice.id}>
                      {prosecutorOffice.name}
                    </option>
                  ))
                : ''}
            </select>
          </Grid>

          {!linking && (
            <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
              <select
                className={classes.selectInput}
                onChange={handleChange}
                value={formState.values.inquiryStep}
                name="inquiryStep"
              >
                <option value="TODAS_AS_FASES">Fases Consensus</option>
                {details
                  ? listSteps(customerConfig)
                      .sort((a, b) => {
                        if (a.name > b.name) {
                          return 1;
                        }
                        if (a.name < b.name) {
                          return -1;
                        }
                        return 0;
                      })
                      .map(item => (
                        <option key={item.id} value={item.value}>
                          {item.name}
                        </option>
                      ))
                  : ''}
                {screeningNotPursuit ? (
                  <option value={STEPS_INQUIRY.TRIAGEM}>Triagem não Persecução</option>
                ) : (
                  ''
                )}
                {searchDistribution ? (
                  <option value={STEPS_INQUIRY.PESQUISA}>Pesquisa e Distribuição</option>
                ) : (
                  ''
                )}
                {analyze ? <option value={STEPS_INQUIRY.ANALISE}>Análise</option> : ''}
                {analyze ? (
                  <option value={STEPS_INQUIRY.ENCAMINHAR_DILIGENCIAS}>
                    Requisição de Diligência
                  </option>
                ) : (
                  ''
                )}
                {intimation ? <option value={STEPS_INQUIRY.INTIMACAO}>Intimação</option> : ''}
                {hearing ? (
                  <option value={STEPS_INQUIRY.AUDIENCIA}>
                    Audiência{enableHearingForJuvenileOffenders ? '/Oitiva' : ''}
                  </option>
                ) : (
                  ''
                )}
                {forwardingTerm ? (
                  <option value={STEPS_INQUIRY.TERMO_DE_ENCAMINHAMENTO}>
                    Termo de Encaminhamento
                  </option>
                ) : (
                  ''
                )}
                {processing ? (
                  <option value={STEPS_INQUIRY.TRAMITACAO_CONTROLE}>Tramitação e Controle</option>
                ) : (
                  ''
                )}
                {agreement ? (
                  <option value={STEPS_INQUIRY.CUMPRIMENTO_ACORDO}>Cumprimento de Acordo</option>
                ) : (
                  ''
                )}
                {migration ? <option value={STEPS_INQUIRY.MIGRACAO}>Migração</option> : ''}
              </select>
            </Grid>
          )}
          {!linking && (
            <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
              <InputMask
                className={classes.inputInquiry}
                type="text"
                placeholder="Número do Inquérito"
                name="policeInquiryNumber"
                onChange={handlePoliceInquiryNumberChange}
                value={formState.values.policeInquiryNumber}
                onBeforeInput={verifyMask}
                mask={mask}
                maskChar={null}
                onPaste={event => {
                  if (!allOriginNumbersAreNpu) {
                    verifyOnPaste(event);
                  }
                }}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
            <InputMask
              className={classes.inputInquiry}
              type="text"
              placeholder="Número interno"
              name="internalSystemNumber"
              mask={'999999999999999'}
              maskChar={null}
              onChange={handleChange}
              value={formState.values.internalSystemNumber}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
            <input
              className={classes.inputInquiry}
              type="text"
              placeholder="Nome do Investigado"
              name="investigatedName"
              onChange={handleChange}
              value={formState.values.investigatedName}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
            <InputMask
              className={classes.inputInquiry}
              type="text"
              placeholder="CPF do Investigado"
              name="investigatedCpf"
              mask={'999.999.999-99'}
              maskChar={null}
              onChange={handleChange}
              value={formState.values.investigatedCpf}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
            <Autocomplete
              onInputChange={(event, newInputValue) => updateValueCrimes(newInputValue)}
              getOptionSelected={(option, value) => option.taxonomy === value.taxonomy}
              getOptionLabel={option => option.taxonomy}
              filterOptions={x => x}
              value={formState.values.crime}
              noOptionsText="Nada foi encontrado"
              onOpen={() => getCrimes(inputSearchCrime)}
              loadingText="Buscando..."
              loading={loadingCrimes}
              clearOnBlur={false}
              options={crimes}
              onChange={(event, newValue) => {
                setFormState(formState => ({
                  ...formState,
                  values: {
                    ...formState.values,
                    crime: newValue,
                  },
                }));
              }}
              renderOption={option => (
                <>{`${option.taxonomy} - Lei: ${option.lawNumber} - Artigo: ${option.article}`}</>
              )}
              renderInput={params => (
                <div ref={params.InputProps.ref}>
                  <input
                    {...params.inputProps}
                    className={classes.inputInquiry}
                    placeholder="Crime cometido"
                  />
                </div>
              )}
            />
          </Grid>
          {!isPM && (
            <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
              <Autocomplete
                onInputChange={(event, newInputValue) => {
                  setInputSearchResponsable(newInputValue);
                  setResponsibles([]);
                  setPageResponsable(1);
                }}
                getOptionSelected={(option, value) => option.name === value.name}
                getOptionLabel={option => option.name}
                value={formState.values.responsibleUser}
                fullWidth
                noOptionsText="Nada foi encontrado"
                loadingText="Buscando..."
                loading={loadingResponsibles}
                clearOnBlur={false}
                options={responsibles}
                onChange={(event, newValue) => {
                  setFormState(formState => ({
                    ...formState,
                    values: {
                      ...formState.values,
                      responsibleUser: newValue,
                    },
                  }));
                }}
                renderOption={option => <>{option.name}</>}
                renderInput={params => (
                  <div ref={params.InputProps.ref}>
                    <input
                      {...params.inputProps}
                      className={classes.inputInquiry}
                      placeholder="Responsável"
                    />
                  </div>
                )}
                ListboxProps={{
                  onScroll: handleScroll,
                }}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
            <input
              className={classes.inputInquiry}
              type="date"
              name="startDate"
              onChange={handleChange}
              value={formState.values.startDate}
              max={formatDateToISO8601(new Date())}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
            <input
              className={classes.inputInquiry}
              type="date"
              name="endDate"
              onChange={handleChange}
              value={formState.values.endDate}
              max={formatDateToISO8601(new Date())}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2} lg={2} xl={1}>
            <select
              className={classes.selectInput}
              name="isArrested"
              onChange={handleChange}
              value={formState.values.isArrested}
            >
              <option value="">Está Preso?</option>
              <option value={true}>Sim</option>
              <option value={false}>Não</option>
            </select>
          </Grid>
          <Grid item xs={12} sm={3} md={2} lg={2} xl={1}>
            <select
              className={classes.selectInput}
              onChange={handleChange}
              name="isFlagrant"
              value={formState.values.isFlagrant}
            >
              <option value="">É Flagrante?</option>
              <option value={true}>Sim</option>
              <option value={false}>Não</option>
            </select>
          </Grid>
          {analyze || formState.values.inquiryStep === STEPS_INQUIRY.ANALISE ? (
          <Grid item xs={12} sm={3} md={2} lg={2} xl={1}>
            <select
              className={classes.selectInput}
              onChange={handleChange}
              name="isArchivalScience"
              value={formState.values.isArchivalScience}
            >
              <option value="">É Ciência de Arquivamento?</option>
              <option value={true}>Sim</option>
              <option value={false}>Não</option>
            </select>
          </Grid>
        ) : null}
          <Grid item xs={12} sm={3} md={2} lg={2} xl={1}>
            <select
              className={classes.selectInput}
              onChange={event => {
                handleChange(event);
                handlePriority(event);
              }}
              name="priority"
              value={formState.values.priority}
            >
              <option value="">Prioridades</option>
              <option value={'ChildTeen'}>Menor de idade</option>
              <option value={'Elderly'}>Idoso(a)</option>
              <option value={'ViolenceAgainstWomen'}>Violência contra mulher</option>
            </select>
          </Grid>
          <Grid item xs={12} sm={3} md={2} lg={2} xl={1}>
            <select
              className={classes.selectInput}
              onChange={handleChange}
              name="hasPendingInquiryArrived"
              value={formState.values.hasPendingInquiryArrived}
            >
              <option value="">Chegou inquérito?</option>
              <option value={true}>Sim</option>
              <option value={false}>Não</option>
            </select>
          </Grid>
          {(intimation || hearing) && (
            <Grid item xs={12} sm={3} md={2} lg={2} xl={1}>
              <select
                className={classes.selectInput}
                name="victimIntimationDeadline"
                onChange={handleChange}
                value={formState.values.victimIntimationDeadline}
              >
                <option value="">Prazo Envio de Documentos</option>
                <option value={GENERIC_DEADLINE.OUT_DEADLINE}>Fora do prazo</option>
                <option value={GENERIC_DEADLINE.CLOSE_DEADLINE}>Perto do prazo</option>
                <option value={GENERIC_DEADLINE.IN_DEADLINE}>Dentro do prazo</option>
              </select>
            </Grid>
          )}
          {processing || formState.values.inquiryStep === 'TRAMITACAO_CONTROLE' ? (
            <>
              <Grid item xs={12} sm={3} md={2} lg={2} xl={1}>
                <select
                  className={classes.selectInput}
                  name="awaitingInstitutionResponse"
                  onChange={handleChange}
                  value={formState.values.awaitingInstitutionResponse}
                >
                  <option value="">Atribuído à PM?</option>
                  <option value={true}>Sim</option>
                  <option value={false}>Não</option>
                </select>
              </Grid>
              <Grid item xs={12} sm={3} md={2} lg={2} xl={1}>
                <select
                  className={classes.selectInput}
                  name="hasActivatedTask"
                  onChange={handleChange}
                  value={formState.values.hasActivatedTask}
                >
                  <option value="">Com ou sem atividades?</option>
                  <option value={true}>Sim</option>
                  <option value={false}>Não</option>
                </select>
              </Grid>
              <Grid item xs={12} sm={3} md={2} lg={2} xl={1}>
                <select
                  className={classes.selectInput}
                  name="tasksDeadline"
                  onChange={handleChange}
                  value={formState.values.tasksDeadline}
                >
                  <option value="">Prazo Tarefas</option>
                  <option value={GENERIC_DEADLINE.OUT_DEADLINE}>Fora do prazo</option>
                  <option value={GENERIC_DEADLINE.CLOSE_DEADLINE}>Perto do prazo</option>
                  <option value={GENERIC_DEADLINE.IN_DEADLINE}>Dentro do prazo</option>
                </select>
              </Grid>
            </>
          ) : null}
          {searchDistribution || formState.values.inquiryStep === STEPS_INQUIRY.PESQUISA ? (
            <Grid item xs={12} sm={3} md={2} lg={2} xl={1}>
              <select
                className={classes.selectInput}
                name="hasDiligenceResponse"
                onChange={handleChange}
                value={formState.values.hasDiligenceResponse}
              >
                <option value="">Requisitório?</option>
                <option value={true}>Sim</option>
                <option value={false}>Não</option>
              </select>
            </Grid>
          ) : null}
          <Grid item xs={12} sm={3} md={3} lg={2} xl={2}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              position="relative"
              width="100%"
            >
              <input
                className={classes.inputInquiry}
                style={{
                  marginRight: 0,
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  paddingRight: '2rem',
                }}
                name="classInquiry"
                value={formState.values.classDescription}
                onClick={handleOpenModalClasse}
                placeholder="Classe"
              />
              <Tooltip title="Limpar Classe" placement="top" arrow>
                <IconButton
                  size="small"
                  onClick={handleClearClasses}
                  style={{
                    position: 'absolute',
                    right: 3,
                    top: 10,
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
          {shouldDisplayReturnedFromPmField(formState.values.inquiryStep) ? (
            <Grid item xs={12} sm={3} md={2} lg={2} xl={1}>
              <select
                className={classes.selectInput}
                name="returnedFromPm"
                onChange={handleChange}
                value={formState.values.returnedFromPm}
              >
                <option value="">Devolvido pela PM?</option>
                <option value={true}>Sim</option>
                <option value={false}>Não</option>
              </select>
            </Grid>
          ) : null}
          {analyze || formState.values.inquiryStep === STEPS_INQUIRY.ANALISE ? (
            <Grid item xs={12} sm={3} md={2} lg={2} xl={1}>
              <select
                className={classes.selectInput}
                onChange={handleChange}
                name="agreementStatus"
                value={formState.values.agreementStatus}
              >
                <option value="">Status do Acordo</option>
                <option value={'notApproved'}>Acordo Não Homologado</option>
                <option value={AGREEMENT_STATUS.FULFILLED}>Acordo Cumprido</option>
                <option value={AGREEMENT_STATUS.NOT_FULFILLED}>Acordo Não Cumprido</option>
              </select>
            </Grid>
          ) : null}
          {agreement ||
            (formState.values.inquiryStep === STEPS_INQUIRY.CUMPRIMENTO_ACORDO && (
              <>
                <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
                  <select
                    className={classes.selectInput}
                    onChange={handleChange}
                    value={formState.values.finalInstitution}
                    name="finalInstitution"
                  >
                    <option value="TODAS_AS_FINAL_INSTITUTIONS">Instituição Final</option>
                    {finalInstitutions.length > 0
                      ? finalInstitutions.map(inst => (
                          <option key={inst.id} value={inst.id}>
                            {inst.name}
                          </option>
                        ))
                      : ''}
                  </select>
                </Grid>
                <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
                  <select
                    className={classes.selectInput}
                    onChange={handleChange}
                    value={formState.values.forwarderInstitution}
                    name="forwarderInstitution"
                  >
                    <option value="TODAS_AS_FORWARDER_INSTITUTIONS">
                      Instituição Encaminhadora
                    </option>
                    {forwarderInstitutions.length > 0
                      ? forwarderInstitutions.map(inst => (
                          <option key={inst.id} value={inst.id}>
                            {inst.name}
                          </option>
                        ))
                      : ''}
                  </select>
                </Grid>
                <Grid item xs={12} sm={3} md={2} lg={2} xl={1}>
                  <select
                    className={classes.selectInput}
                    name="hasPendenciesInAgreement"
                    onChange={handleChange}
                    value={formState.values.hasPendenciesInAgreement}
                  >
                    <option value="">Pendente de cumprimento?</option>
                    <option value={true}>Sim</option>
                    <option value={false}>Não</option>
                  </select>
                </Grid>
              </>
            ))}
          {aiAssistant?.read && (
            <CustomerDisplayValidator customerConfig={CUSTOMER_CONFIG.CONNECT_WITH_AI_SERVICE}>
              <Tooltip title="Buscar inquéritos com IA" arrow placement="top">
                <IconButton
                  onClick={handleOpenChatAiSearchInquiries}
                  disabled={!aiAssistant?.write}
                >
                  <AutoAwesomeIcon />
                </IconButton>
              </Tooltip>
            </CustomerDisplayValidator>
          )}
          <Grid item xs={12} sm={3} md={1} lg={1} xl={1}>
            <Button className={classes.btnSearch} type="submit" hovercolor="#00b1ac">
              <SearchIcon />
            </Button>
          </Grid>
        </Grid>
      </form>

      {showProgress && (
        <div className={classes.circularProgress}>
          <CircularProgress color="primary" style={{ height: 70, width: 70 }} />
        </div>
      )}

      {stateSearch ? (
        <>
          <MsgReturnSearch
            step={step}
            sizeDataApi={sizeDataApi}
            page={page}
            size={size}
            total={total}
            lastPage={totalPages}
          />
          <Card className={classes.classeList}>
            {isResult && <ViewCardsInquiries inquiries={inquiries} linking={linking} />}
          </Card>
        </>
      ) : (
        <>
          <Typography className={classes.resumoBusca} />
          <Typography className={classes.classeList} />
        </>
      )}

      <div className={classes.pagination}>
        <Pagination
          size="small"
          count={totalPages}
          page={page}
          color="primary"
          onChange={handlePageChange}
        />
      </div>
      <ModalClasses
        open={openModalClasse}
        onClose={handleCloseModalClasse}
        onClick={handleAddClasse}
      />
    </Card>
  );
};

export default FilterInquiry;

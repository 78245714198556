/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Dialog, Typography, Grid, DialogContent, Box, IconButton } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { useDropzone } from 'react-dropzone';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { toast } from 'react-toastify';
import { HeaderModal, ButtonConsensus, InputConsensus } from 'components';
import { colors } from 'utils/colorsSteps';
import { onCloseWithBackdropDialog } from 'utils/materialUI/backdropClickDialog';
import { MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import investigatedCertificateService from 'services/investigated-certificates/investigatedCertificateService';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import history from 'utils/history';

const useStyles = makeStyles(() => ({
  dropzone: {
    height: '100%',
    width: '100%',
    border: '1px dashed #292929',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 10,
    cursor: 'pointer',
  },
  inputMaterial: {
    width: '100%',
  },
}));

const certificateTypes = {
  VITIMA: 'Vítima',
  INVESTIGADO: 'Investigado',
  DELEGADO: 'Delegado',
  JUIZO: 'Juízo',
}

const ModalAddFile = props => {
  const { open, onClose, inquiryInvestigatedId } = props;
  const classes = useStyles();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [files, setFiles] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [type, setType] = useState('');

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCertificates();
  }, []);

  const getCertificates = async () => {
    setLoading(true);
    investigatedCertificateService
      .getByInvestigatedService(inquiryInvestigatedId)
      .then(response => {
        setCertificates(certificates => [...certificates, ...response.data]);
      })
      .catch(() => {
        toast.error('Erro ao buscar certidões');
      })
      .finally(() => setLoading(false));
  };

  const handleChangeType = event => {
    setType(event.target.value);
  };

  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    onDrop: files => getBase64(files),
  });

  const getBase64 = files => {
    files.forEach(file => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64 = reader.result;
        setFiles(files => [
          ...files,
          {
            file: {
              name: file.name,
              base64,
            }
          },
        ]);
      };
      reader.onerror = function(error) {
        toast.warn(error);
      };
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (!type) {
      toast.warning('É necessário selecionar o tipo de certidão');
      return;
    }
    setCertificates(certificates => [...certificates, ...files.map(file => ({ ...file, type }))]);
    setFiles([]);
  };

  const saveCertificatesList = event => {
    event.preventDefault();
    if (
      !certificates.some(c => c.type === certificateTypes.INVESTIGADO) ||
      !certificates.some(c => c.type === certificateTypes.DELEGADO) ||
      !certificates.some(c => c.type === certificateTypes.VITIMA)
    ) {
      toast.warning('É necessário adicionar ao menos uma certidão de cada tipo');
      return;
    }
    setLoading(true);
    investigatedCertificateService.saveCertificateService({
      certificates: certificates.filter(c => c.type !== certificateTypes.JUIZO),
      inquiryInvestigatedId,
    }).then(() => {
      toast.success('Certidões adicionadas com sucesso e inquérito enviado para Análise');
      onClose();
      setLoading(false);
      history.push('/analise');
    }).catch(() => {
      toast.error('Erro ao adicionar certidões');
      setLoading(false);
    })
  };

  return (
    <div className={clsx(classes.root)}>
      <Dialog
        fullScreen={fullScreen}
        onClose={(event, reason) => onCloseWithBackdropDialog(event, reason, onClose)}
        open={open}
        aria-labelledby="responsive-dialog-title"
        className={classes.modal}
        fullWidth
        maxWidth="md"
      >
        <HeaderModal
          backgroundColor={colors.navBar}
          colorIconTitle={colors.greySecundary}
          onClose={onClose}
        >
          Adicionar Certidões
        </HeaderModal>
        <DialogContent>
          <form
            onSubmit={
              certificates.length > 0 && files.length === 0 ? saveCertificatesList : handleSubmit
            }
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{ minHeight: 150, padding: 20 }}
              >
                <div {...getRootProps({ className: 'dropzone' })} className={classes.dropzone}>
                  <input {...getInputProps()} disabled={loading} />
                  <Typography>Arraste um arquivo, ou clique para adicionar</Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="h5">Total de arquivos: {certificates.length}</Typography>
              </Grid>
            </Grid>
            {certificates.length > 0 && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Nome</TableCell>
                    <TableCell align="center">Tipo</TableCell>
                    <TableCell align="center">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {certificates.map((certificate, index) => (
                    <TableRow key={certificate.file?.name || certificate.name}>
                      <TableCell align="center">{certificate.file?.name || certificate.name}</TableCell>
                      <TableCell align="center">{certificate.type}</TableCell>
                      <TableCell align="center">
                        <Tooltip arrow placement="top" title="Alterar tipo da certidão">
                          <IconButton
                            color="primary"
                            onClick={() => {
                              setCertificates(certificates => [
                                ...certificates.filter((_item, i) => i !== index),
                              ]);
                              setFiles([certificate]);
                            }}
                            disabled={certificate.type === certificateTypes.JUIZO}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="top" title="Remover arquivo">
                          <IconButton
                            color="primary"
                            onClick={() => {
                              setCertificates(certificates => [
                                ...certificates.filter((_item, i) => i !== index),
                              ]);
                            }}
                            disabled={certificate.type === certificateTypes.JUIZO}
                          >
                            <ClearIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
            {files.map((file, i) => (
              <Box display="flex" alignItems="center" key={i} width="100%">
                <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                  <Box display="flex" alignItems="center">
                    <PictureAsPdfIcon style={{ marginRight: 5 }} />
                    <Typography variant="body1">{file.file?.name}</Typography>
                  </Box>
                  <IconButton
                    onClick={() =>
                      setFiles(files => files.filter((_item, fileIndex) => fileIndex !== i))
                    }
                  >
                    <HighlightOffIcon style={{ color: '#e81224' }} />
                  </IconButton>
                </Box>
              </Box>
            ))}
            {files.length > 0 && (
              <InputConsensus
                select
                label="Tipo de certidão"
                name="type"
                onChange={handleChangeType}
                value={type}
                className={classes.inputMaterial}
              >
                <MenuItem value={certificateTypes.INVESTIGADO}>{certificateTypes.INVESTIGADO}</MenuItem>
                <MenuItem value={certificateTypes.VITIMA}>{certificateTypes.VITIMA}</MenuItem>
                <MenuItem value={certificateTypes.DELEGADO}>{certificateTypes.DELEGADO}</MenuItem>
              </InputConsensus>
            )}
            <Grid container style={{ marginTop: 20, marginBottom: 20 }}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <ButtonConsensus
                  title={
                    certificates.length > 0 && files.length === 0 ? 'Salvar Certidões' : 'Adicionar'
                  }
                  type="submit"
                  disabled={(!files.length && !certificates.length) || loading}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModalAddFile;
